import React, { useState, useEffect } from 'react'
import HeaderTitle from '../HeaderTitle'
import { Alert, AlertTitle } from "@material-ui/lab";
import { Dialog, DialogContent } from "@material-ui/core";
import "../../../Alerta.css";
import DataTableFilterDemo from 'components/Datatable';
import ProjectsList from "components/dashboard/Intranet/ProjectsList";
import { alertaSecadoVirtual, obtenerJornadasAbiertas, obtenerOrdenCompra, obtenerConsecutivo } from './Alertas.services';

const PanelAlertas = ({ updateData, identificadorEstacion }: any): JSX.Element => {
    const [dataModal, setDataModal] = useState({} as any)
    const [jornadasAbiertas, setJornadasAbiertas] = useState([] as any)
    const [consecutivos, setConsecutivos] = useState([] as any)
    const [ordenesCompra, setOrdenesCompra] = useState([] as any)
    const [secadoVirtual, setSecadoVirtual] = useState([] as any)

    const modalAlertas = (title: string) => {
        setDataModal({
            [title]: false,
            isShow: true,
            alerta: title,
        })
    };

    useEffect(() => {
        getData()
    }, [updateData, identificadorEstacion])

    const getData = async () => {
        setSecadoVirtual(await alertaSecadoVirtual(identificadorEstacion))
        setJornadasAbiertas(await obtenerJornadasAbiertas(identificadorEstacion))
        setConsecutivos(await obtenerConsecutivo(identificadorEstacion))
        setOrdenesCompra(await obtenerOrdenCompra(identificadorEstacion))
    }

    const detallesConsecutiva = () => {
        return (
            <div className="mx-auto">
                <DataTableFilterDemo
                    paginator={true}
                    rows={10}
                    data={consecutivos}
                    columns={[
                        {
                            field: "prefijo",
                            header: "PREFIJO",
                        },
                        {
                            field: "resolucion",
                            header: "RESOLUCION",
                        },
                        {
                            field: "fecha_inicio",
                            header: "FECHA INICIAL",
                        },
                        {
                            field: "fecha_fin",
                            header: "FECHA FINAL",
                        },
                        {
                            field: "consecutivo_inicial",
                            header: "CONS. INICIAL",
                            number: true,
                        },
                        {
                            field: "consecutivo_actual",
                            header: "CONS. ACTUAL",
                            number: true,
                        },
                        {
                            field: "consecutivo_final",
                            header: "CONS. FINAL",
                            number: true,
                        },
                        {
                            field: "consecutivo_restante",
                            header: "CONS. RESTANTES",
                        },
                    ]}
                />
            </div>
        );
    };
    const detallesJordanaActiva = () => {
        return (
            <div className="col-lg-12 col-12">
                <div className="jr-card">
                    <ProjectsList data={jornadasAbiertas} />
                </div>
            </div>
        );
    };
    const detallesCompraAbierta = () => {
        return (
            <div className="row mx-auto">
                <DataTableFilterDemo
                    paginator={true}
                    rows={10}
                    data={ordenesCompra}
                    columns={[
                        {
                            field: "consecutivo",
                            header: "CONSECUTIVO",
                        },
                        {
                            field: "fecha",
                            header: "FECHA",
                        },
                        {
                            field: "responsable",
                            header: "RESPONSABLE",
                        },
                        {
                            field: "proovedor",
                            header: "PROOVEDOR",
                        },
                        {
                            field: "costo_total",
                            header: "TOTAL",
                        },
                    ]}
                />
            </div>
        );
    };
    const detallesSecadoVirtual = () => {
        return (
            <div className="row mx-auto">
                <DataTableFilterDemo
                    paginator={true}
                    rows={10}
                    data={secadoVirtual}
                    columns={[
                        {
                            field: "bodega",
                            header: "BODEGA",
                        },
                        {
                            field: "codigo",
                            header: "CODIGO",
                        },
                        {
                            field: "descripcion",
                            header: "DESCRIPCION",
                        },
                        {
                            field: "saldo",
                            header: "SALDO",
                        },
                        {
                            field: "volumen_minimo",
                            header: "VOLUMEN MINIMO",
                        },
                    ]}
                />
            </div>

        );
    };

    return (
        <>
            <HeaderTitle title='ALERTAS' />
            <div className="container noDatos">
                <div className="row mx-auto">
                    <div className="col-md-3 mx-auto">
                        <Alert
                            className={
                                jornadasAbiertas.length > 0 ? "hoverAlertas alerta" : "hoverAlertas"
                            }
                            style={{ marginTop: "10px" }}
                            severity="success"
                            onClick={() => modalAlertas("jornada_abierta")}
                        >
                            <AlertTitle>JORNADAS ABIERTAS</AlertTitle>
                            <strong>CLICK AQUI!</strong> PARA VER DETALLES
                        </Alert>
                    </div>
                    <div className="col-md-3 mx-auto">
                        <Alert
                            className={
                                consecutivos.length > 0 ? "hoverAlertas alerta" : "hoverAlertas"
                            }
                            style={{ marginTop: "10px" }}
                            severity="error"
                            onClick={() => modalAlertas("consecutivo")}
                        >
                            <AlertTitle>CONSECUTIVO</AlertTitle>
                            <strong>CLICK AQUI!</strong> PARA VER DETALLES
                        </Alert>
                    </div>
                    <div className="col-md-3">
                        <Alert
                            className={secadoVirtual.length > 0 ? "hoverAlertas alerta" : "hoverAlertas"}
                            style={{ marginTop: "10px" }}
                            severity="warning"
                            onClick={() => modalAlertas("secado_virtual")}
                        >
                            <AlertTitle>SECADO VIRTUAL</AlertTitle>
                            <strong>CLICK AQUI!</strong> PARA VER DETALLES
                        </Alert>
                    </div>
                    <div className="col-md-3 mx-auto">
                        <Alert
                            className={
                                ordenesCompra.length > 0 ? "hoverAlertas alerta" : "hoverAlertas"
                            }
                            style={{ marginTop: "10px" }}
                            severity="info"
                            onClick={() => modalAlertas("ordenes_entrada")}
                        >
                            <AlertTitle>ORDENES DE COMPRAS SIN ENTRADA</AlertTitle>
                            <strong>CLICK AQUI!</strong> PARA VER DETALLES
                        </Alert>
                    </div>
                </div>
            </div>
            <Dialog
                open={dataModal.isShow}
                aria-labelledby="form-dialog-title"
                maxWidth={"md"}
                fullWidth={true}
                style={{ padding: "0", zIndex: 500 }}
                disableBackdropClick={false}
            >
                <DialogContent style={{ padding: "30px", zIndex: 500 }}>
                    <button
                        className="btn btn-primary"
                        onClick={() => setDataModal({ ...dataModal, isShow: false })}
                    >
                        Cerrar
                    </button>
                    <hr />
                    <div className="row">
                        <h2 className="mx-auto">
                            {dataModal.alerta && dataModal.alerta.toLocaleUpperCase() !==
                                "JORNADA_ABIERTA" && "ALERTAS DE"}{" "}
                            {dataModal.alerta && dataModal.alerta.replace("_", " ").toLocaleUpperCase()}
                        </h2>
                    </div>
                    {dataModal.alerta && dataModal.alerta.toLocaleUpperCase() === "CONSECUTIVO"
                        ? detallesConsecutiva()
                        : dataModal.alerta && dataModal.alerta.toLocaleUpperCase() === "JORNADA_ABIERTA"
                            ? detallesJordanaActiva()
                            : dataModal.alerta && dataModal.alerta.toLocaleUpperCase() === "SECADO_VIRTUAL"
                                ? detallesSecadoVirtual()
                                : detallesCompraAbierta()}
                </DialogContent>
            </Dialog>

        </>
    )
}
export default PanelAlertas
