import Loader from 'components/Loader'
import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
//rutas

const AnulacionesVentas = lazy(async () => import('./anulacionesVentas'))
const Categoria = lazy(async () => import('./ReporteCategoria'))
const CierreDatagas = lazy(async () => import('./cierreDatagas'))
const DiferenciaRecibo = lazy(async () => import('./DiferenciaRecibo'))
const KardexCanastilla = lazy(async () => import('./ReporteKardexCanastilla'))
const LecturaTanque = lazy(async () => import('./LecturaTanque'))
const LibroFacturacionCanastilla = lazy(async () => import('./LibroFacturacionCanastilla'))
const LibroFacturacionCombustible = lazy(async () => import('./LibroFacturacionCombustible'))
const Producto = lazy(async () => import('./ReporteProducto'))
const RecaudosEstacion = lazy(async () => import('./RecaudosEstacion'))
const ReporteVentasAnticipo = lazy(async () => import('./ReporteVentasAnticipo'))
const ReportesConsumo = lazy(async () => import('./ReporteConsumo'))
const VentasDatagas = lazy(async () => import('./ventaDatagas'))
const VentasDiaDatagas = lazy(async () => import('./ventaDatagas/indexDia'))
const VentasEquipoDatagas = lazy(async () => import('./ventaDatagas/indexEquipo'))
const VentasRecaudo = lazy(async () => import('./VentasRecaudo'))
// ya en TYPESCRIPT
const AlertaConversiones = lazy(async () => import('./AlertaConversiones'))
const BalanceAcumulado = lazy(async () => import('./BalanceAcumulado'))
const BalanceOperativo = lazy(async () => import('./BalanceOperativo/index.ts'))
const Calibraciones = lazy(async () => import('./Calibraciones/'))
const Cierre24Horas = lazy(async () => import('./cierreDatagas/Cierre24Horas'))
const CierreDiaCompleto = lazy(async () => import('./cierreDatagas/CierreDiaCompleto'))
const CierreDiario = lazy(async () => import('./CierreDiario'))
const ConciliacionDiaria = lazy(async () => import('./ConciliacionDiaria'))
const ConsumoPropio = lazy(async () => import('./ConsumoPropio'))
const CumplimientoDiario = lazy(async () => import('./CumplimientoDiario'))
const HistoricoDePrecios = lazy(async () => import('./HistoricoDePrecios'))
const KardexCombustible = lazy(async () => import('./ReporteKardexCombustible/'))
const LecturaManguera = lazy(async () => import('./LecturaManguera'))
const LibroFacturacion = lazy(async () => import('./LibroFacturacion'))
const Millas = lazy(async () => import('./Millas'))
const ReporteArqueo = lazy(async () => import('./ReporteArqueo/index'))
const ReporteConsolidadoTurno = lazy(async () => import('./ReporteConsolidadoTurno'))
const ReporteConversiones = lazy(async () => import('./ReporteConversiones'))
const ReporteUtilidad = lazy(async () => import('./ReporteUtilidad'))
const ReporteVentas = lazy(async () => import('./ReporteVentas/ReporteVentas'))
const ReporteVentasCanastilla = lazy(async () => import('./ReporteVentas/ReporteVentasCanastilla'))
// const ReporteVentasClientes = lazy(async () => import('./ReporteVentas/ReporteVentasClientes'))
const ReporteVentasClientes = lazy(async () => import('./ReporteVentas/ReporteVentaClientesLite'))
const ReporteVentasCombustible = lazy(async () => import('./ReporteVentas/ReporteVentaCombustible'))
const ReporteVentasMixtas = lazy(async () => import('./ReporteVentas/ReporteVentasMixtas'))
const ReporteVentasPlaca = lazy(async () => import('./ReporteVentas/ReporteVentasPlacas'))
const ReporteVentasProducto = lazy(async () => import('./ReporteVentas/ReporteVentasProducto'))
const ReporteVentasPromotor = lazy(async () => import('./ReporteVentas/ReporteVentasPromotor'))
const ReportesCierres = lazy(async () => import('./ReportesCierres'))
const ReportesCompras = lazy(async () => import('./ReportesCompras'))
const VentasEDS = lazy(async () => import('./VentasEDS/index'))
const VentasRecaudoDetallado = lazy(async () => import('./VentasRecaudoDetalladas'))
const ReporteVentaCombustibleFidelizadas = lazy(async () => import('./ReporteVentas/ReporteVentaCombustibleFidelizadas'))

const RemisionesElectronicas = lazy(async () => import('./RemisionesElectronicas'))

const Reportes = ({ match }) => {
  return (
    <div className="app-wrapper">
      <Switch>
        <Suspense fallback={<Loader />}>
          <Route path={`${match.url}/alerta_conversiones`} component={AlertaConversiones} />
          <Route path={`${match.url}/anulaciones_ventas`} component={AnulacionesVentas} />
          <Route path={`${match.url}/balance-acumulado`} component={BalanceAcumulado} />
          <Route path={`${match.url}/balance-operativo`} component={BalanceOperativo} />
          <Route path={`${match.url}/calibraciones`} component={Calibraciones} />
          <Route path={`${match.url}/cierre-dia-24-horas`} component={Cierre24Horas} />
          <Route path={`${match.url}/cierre-dia-completo`} component={CierreDiaCompleto} />
          <Route path={`${match.url}/cierre_diario`} component={CierreDiario} />
          <Route path={`${match.url}/conciliacion_diaria`} component={ConciliacionDiaria} />
          <Route path={`${match.url}/consolidado-cierre-turno`} component={ReporteConsolidadoTurno} />
          <Route path={`${match.url}/consumo-propio`} component={ConsumoPropio} />
          <Route path={`${match.url}/consumos`} component={ReportesConsumo} />
          <Route path={`${match.url}/conversiones`} component={ReporteConversiones} />
          <Route path={`${match.url}/cuplimiento-diario`} component={CumplimientoDiario} />
          <Route path={`${match.url}/diferencia-recibo`} component={DiferenciaRecibo} />
          <Route path={`${match.url}/historico-cierres`} component={CierreDatagas} />
          <Route path={`${match.url}/historicos-precios`} component={HistoricoDePrecios} />
          <Route path={`${match.url}/kardex-canastilla`} component={KardexCanastilla} />
          <Route path={`${match.url}/kardex-combustible`} component={KardexCombustible} />
          <Route path={`${match.url}/lectura-manguera`} component={LecturaManguera} />
          <Route path={`${match.url}/lectura-tanque`} component={LecturaTanque} />
          <Route path={`${match.url}/libro_facturacion_canastilla`} component={LibroFacturacionCanastilla} />
          <Route path={`${match.url}/libro_facturacion_combustible`} component={LibroFacturacionCombustible} />
          <Route path={`${match.url}/libro_facturacion`} component={LibroFacturacion} />
          <Route path={`${match.url}/millas`} component={Millas} />
          <Route path={`${match.url}/recaudos_estacion`} component={RecaudosEstacion} />
          <Route path={`${match.url}/reporte-ventas-mixta`} component={ReporteVentasMixtas} />
          <Route path={`${match.url}/reporte_arqueo`} component={ReporteArqueo} />
          <Route path={`${match.url}/reporte_cierres`} component={ReportesCierres} />
          <Route path={`${match.url}/reporte_compras`} component={ReportesCompras} />
          <Route path={`${match.url}/reporteventas`} component={ReporteVentas} />
          <Route path={`${match.url}/reporteventascanastilla`} component={ReporteVentasCanastilla} />
          <Route path={`${match.url}/reporteventascombustible`} component={ReporteVentasCombustible} />
          <Route path={`${match.url}/utilidad-categoria`} component={Categoria} />
          <Route path={`${match.url}/utilidad-producto`} component={Producto} />
          <Route path={`${match.url}/utilidad`} component={ReporteUtilidad} />
          <Route path={`${match.url}/ventas_anticipo`} component={ReporteVentasAnticipo} />
          <Route path={`${match.url}/ventas_clientes_consumo`} component={ReporteVentasClientes} />
          <Route path={`${match.url}/ventas_data`} component={VentasDatagas} />
          <Route path={`${match.url}/ventas_dias_datagas`} component={VentasDiaDatagas} />
          <Route path={`${match.url}/ventas_eds`} component={VentasEDS} />
          <Route path={`${match.url}/ventas_equipo_datagas`} component={VentasEquipoDatagas} />
          <Route path={`${match.url}/ventas_placas`} component={ReporteVentasPlaca} />
          <Route path={`${match.url}/ventas_productos`} component={ReporteVentasProducto} />
          <Route path={`${match.url}/ventas_promotor`} component={ReporteVentasPromotor} />
          <Route path={`${match.url}/ventas_recaudos_detalladas`} component={VentasRecaudoDetallado} />
          <Route path={`${match.url}/ventas_recaudos`} component={VentasRecaudo} />
          <Route path={`${match.url}/ventas-fidelizadas`} component={ReporteVentaCombustibleFidelizadas} />
          <Route path={`${match.url}/remisiones_electronicas`} component={RemisionesElectronicas} />

        </Suspense>
      </Switch>
    </div>
  )
}

export default Reportes
