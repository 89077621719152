import Axios from 'axios';
import Servidor from 'util/Servidor';
import { sessionCaducada, setearMiles } from 'util/funciones'
import moment from 'moment';
import Storage from 'util/store/Storage'


const calcularPorcentaje = (datosArr: any[]) => {

    let total = 0;
    let porcentaje: any[] = [];
    datosArr.forEach((dat) => {
        total += parseInt(dat);
    });
    datosArr.forEach((dat) => {
        porcentaje.push((parseInt(dat) * 100) / total);
    });
    return porcentaje;
}
export const alertaSecadoVirtual = async (identificadorEstacion: string) => {
    const headers = {
        Authorization: Servidor.obtenerToken(),
        versionapp: Servidor.versionApp,
        versioncode: Servidor.versionCode,
        fecha: moment().format(),
        aplicacion: Servidor.aplicacion,
        identificadordispositivo: Servidor.aplicacion,
    };
    const data = {
        fecha_inicial: moment(new Date()).format("YYYY-MM-DD 00:00:00"),
        fecha_final: moment(new Date()).format("YYYY-MM-DD 24:00:00"),
        identificadorEstacion
    };
    return new Promise(async function (resolve, reject) {
        Axios.post(Servidor.host + Servidor.puertoMovimiento + "/api/reportes/dashboard-lite/ALERTA_TANQUES", data, { headers: headers })
            .then((res) => {
                if (res.status === Servidor.responseOK) {
                    const datos = res.data.data;
                    let tanques = []
                    if (datos) {
                        tanques = datos
                    }
                    resolve(tanques)
                } else if (res.status === Servidor.responseSesionCaducada) {
                    sessionCaducada()
                }
            })
            .catch((err) => {
                reject(err)
            })
    })
};
export const obtenerJornadasAbiertas = async (identificadorEstacion: string) => {
    const headers = {
        Authorization: Servidor.obtenerToken(),
        versionapp: Servidor.versionApp,
        versioncode: Servidor.versionCode,
        fecha: moment().format(),
        aplicacion: Servidor.aplicacion,
        identificadordispositivo: Servidor.aplicacion,
    };
    const data = {
        fecha_inicial: moment(new Date()).format("YYYY-MM-DD 00:00:00"),
        fecha_final: moment(new Date()).format("YYYY-MM-DD 24:00:00"),
        identificadorEstacion
    };


    try {

        return new Promise(async function (resolve, reject) {
            const res = await Axios.post(
                Servidor.host +
                Servidor.puertoMovimiento +
                "/api/reportes/dashboard-lite/alerta_jornadas_abiertas",
                data,
                { headers: headers }
            );
            let porcentaje = calcularPorcentaje(
                res.data.data.map((dat: any) => setearMiles(dat.num_ventas))
            );
            const datos: any[] = [];
            let porcen = 0;
            res.data.data.forEach((dat: any, i: number) => {
                porcen = porcentaje[i];
                datos.push({
                    ...dat,
                    porcentaje: porcen,
                    hora: moment(dat.fecha_inicio, 'YYYY-MM-DDTHH:mm:ss').format('MMMM DD YYYY, h:mm A').toUpperCase()
                });
            });
            resolve(datos)
        })

    } catch (error) { }
};

export const obtenerConsecutivo = async (identificadorEstacion: string) => {
    const { empresasHijos } = Storage.getState();
    const headers = {
        Authorization: Servidor.obtenerToken(),
        versionapp: Servidor.versionApp,
        versioncode: Servidor.versionCode,
        fecha: moment().format(),
        aplicacion: Servidor.aplicacion,
        identificadordispositivo: Servidor.aplicacion,
    };
    const data = {
        fecha_inicial: moment(new Date()).format("YYYY-MM-DD 00:00:00"),
        fecha_final: moment(new Date()).format("YYYY-MM-DD 24:00:00"),
        identificadorEstacion
    };


    try {

        return new Promise(async function (resolve, reject) {
            const res = await Axios.post(
                Servidor.host +
                Servidor.puertoMovimiento +
                "/api/reportes/dashboard-lite/alerta_consecutivos",
                data,
                { headers: headers }
            );
            let data1: any[] = [];
            res.data.data.forEach((dat: any) => {
                data1.push({
                    ...dat,
                    fecha_inicio: moment(dat.fecha_inicio).format("DD/MM/YYYY"),
                    fecha_fin: moment(dat.fecha_fin).format("DD/MM/YYYY"),
                    consecutivo_restante:
                        parseInt(dat.consecutivo_final) - parseInt(dat.consecutivo_actual),

                });
            });
            resolve(data1)
        })

    } catch (error) { }
};

export const obtenerOrdenCompra = async (identificadorEstacion: string) => {
    try {

        return new Promise(async function (resolve, reject) {
            const res = await Axios.get(
                Servidor.host +
                Servidor.puertoCentury +
                "/api/movimiento/orden-compra-activa/" +
                identificadorEstacion,
                { headers: { Authorization: Servidor.obtenerToken() } }
            );
            const data: any[] = [];
            res.data.mensaje.map((dat: any) => (
                data.push({
                    dat,
                    fecha: moment(dat.fecha).format("YYYY-MM-DD"),
                    costo_total: "$ " + setearMiles(dat.costo_total),
                })
                /* (dat.fecha = moment(dat.fecha).format("YYYY-MM-DD"))
                 (dat.costo_total = "$ " + setearMiles(dat.costo_total))*/
            ));

            resolve(res.data.mensaje)
        })

    } catch (error) { }
};
