import Loader from 'components/Loader'
import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
//rutas
const AjusteInicial = lazy(async () => import('./AjusteInicial'))
const Entrada = lazy(async () => import('./Entrada'))
const OrdenCompra = lazy(async () => import('./OrdenCompra'))
const ProductosKiosco = lazy(async () => import('./ProductosKiosco'))
//const ProductosKiosco = lazy(async () => import('./Productos'))
const ReporteVentasKiosco = lazy(async () => import('../Reportes/ReporteVentas/ReporteVentasKiosco'))
const ReporteVentasKioscoDetallada = lazy(async () => import('../Reportes/ReporteVentas/ReporteVentasKioscoDetallada'))

const Configuracion = ({ match }: any) => {
  return (
    <div className="app-wrapper">
      <Switch>
        <Suspense fallback={<Loader />}>
          <Route path={`${match.url}/ajuste-inicial-kiosco`} component={AjusteInicial} />
          <Route path={`${match.url}/entrada-kiosco`} component={Entrada} />
          <Route path={`${match.url}/ordenes-compra-kiosco`} component={OrdenCompra} />
          <Route path={`${match.url}/productos-kiosco`} component={ProductosKiosco} />
          <Route path={`${match.url}/ventas-kiosco-detallada`} component={ReporteVentasKioscoDetallada} />
          <Route path={`${match.url}/ventas-kiosco`} component={ReporteVentasKiosco} />
        </Suspense>
      </Switch>
    </div>
  )
}

export default Configuracion
