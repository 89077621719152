import React, { useEffect, useState } from 'react'
//panel del filtro
import HeaderTitle from './Components/HeaderTitle'
import Highcharts from 'highcharts'
import HCSoldGauge from 'highcharts/modules/solid-gauge';
import FiltrosFecha from './Components/FiltrosFecha';
import { getChartsData } from '../ChartHelpers/Charts.services';
import { IDatasetChart } from '../ChartHelpers/Charts.interface';
import GraficaTanques from 'app/vistas/Configuracion/MedicionTanques/GraficaTanques';
window.Highcharts = Highcharts;
//ChartModuleMore(Highcharts);
HCSoldGauge(Highcharts);
const ChartSaldosTanques = ({ updateData, identificadorEstacion }: any): JSX.Element => {
    const [dataSet, setDataset] = useState<any[]>([])

    useEffect(() => {
        getData()
    }, [updateData])

    useEffect(() => { }, [dataSet])

    const getData = async (): Promise<void> => {
        const body = {
            identificadorEstacion: identificadorEstacion
        };
        const data = await getChartsData('SALDOS_TANQUES', body)
        setDataset(data)
        crearGraficas(data)
    }

    const crearGraficas = (data: any[]) => {
        try {
            data.forEach((data: any, key) => {
                let { maximo, saldo, unidad, descripcion, tanque, alias } = data
                const color = '#000'
                Highcharts.chart(('saldo-tanque-' + key), {

                    chart: {
                        type: 'gauge',
                        plotBackgroundColor: null,
                        plotBackgroundImage: null,
                        plotBorderWidth: 0,
                        plotShadow: false
                    },

                    title: {
                        text: "",
                    },

                    pane: {
                        startAngle: -150,
                        endAngle: 150,
                        background: [{
                            backgroundColor: {
                                linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                                stops: [
                                    [0, '#FFF'],
                                    [1, '#333']
                                ]
                            },
                            borderWidth: 0,
                            outerRadius: '109%'
                        }, {
                            backgroundColor: {
                                linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                                stops: [
                                    [0, '#333'],
                                    [1, '#FFF']
                                ]
                            },
                            borderWidth: 1,
                            outerRadius: '107%'
                        }, {
                            // default background
                        }, {
                            backgroundColor: '#DDD',
                            borderWidth: 0,
                            outerRadius: '105%',
                            innerRadius: '103%'
                        }]
                    },

                    // the value axis
                    yAxis: {
                        min: 0,
                        max: parseFloat(maximo),

                        minorTickInterval: 'auto',
                        minorTickWidth: 1,
                        minorTickLength: 10,
                        minorTickPosition: 'inside',
                        minorTickColor: '#666',

                        tickPixelInterval: 30,
                        tickWidth: 2,
                        tickPosition: 'inside',
                        tickLength: 10,
                        tickColor: '#666',
                        labels: {
                            step: 2,
                            rotation: 'auto'
                        },
                        title: {
                            text: unidad
                        },
                        plotBands: [{
                            from: 0,
                            to: parseFloat(maximo) / 3.3,
                            color: '#DF5353' // red
                        }, {
                            from: parseFloat(maximo) / 3.3,
                            to: (parseFloat(maximo) / 3.3) * 2,
                            color: '#DDDF0D' // yellow
                        }, {
                            from: (parseFloat(maximo) / 3.3) * 2,
                            to: parseFloat(maximo),
                            color: '#55BF3B' // green
                        }]
                    },

                    series: [{
                        name: unidad,
                        data: [parseFloat(saldo)],
                        color: color,
                        dataLabels: {
                            format:
                                '<div style="text-align:center">' +
                                '<span style="font-size:15px">{y}</span><br/>' +
                                '<span style="font-size:11px;opacity:0.4">' + unidad + '</span><br/>' +
                                '<span style="font-size:10px;opacity:0.4">' + tanque + ' - ' + descripcion + '</span><br/>' +
                                '<span style="font-size:10px;opacity:0.4">' + alias + '</span><br/>' +
                                '</div>'
                        },

                        tooltip: {
                            valueSuffix: unidad
                        }
                    }]

                } as any)

            })
        } catch (error) {
            console.log('Error al caragr los tanques')
        }

    }


    return (
        <>
            <HeaderTitle title={'INVENTARIO DE TANQUES'} />
            <div className="row">
                {
                    dataSet &&
                    dataSet.map((data, key) => <>

                        <div id={"saldo-tanque-" + key} className="col-md-4 mx-auto" key={key}></div>
                    </>
                    )
                }
            </div>
        </>
    )
}
export default ChartSaldosTanques
