import React from "react";
import estacion from "../../../assets/images/widget/mstation.png";
import ventasCanastillaImg from "../../../assets/images/widget/mmoney.png";
import producto from "../../../assets/images/widget/mpro.png";
import carro from "../../../assets/images/widget/car.png";
import usuarioImg from "../../../assets/images/widget/muser.png";
import ventasFidelizadasImg from "../../../assets/images/widget/ventasFidelizadas.png";
import ventasCombustiblesImg from "../../../assets/images/widget/ventasCombustibles.png";
import compras_1Img from "../../../assets/images/widget/compras_1.png";
import creditosIMG from "../../../assets/images/widget/creditos.png";
import financieraIMG from "../../../assets/images/widget/financiera.png";
import acuerdoEds from "../../../assets/images/widget/acuerdoseds.png";
import Selects from "react-select";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Accordion, AccordionSummary } from '@material-ui/core';
import ContainerHeader from "components/ContainerHeader/index";
import { setearMiles, calcularPorcentaje } from "util/funciones";
import Swal from "sweetalert2";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { alertaSecadoVirtual, getEmpresasHijos, getIndicadoresFuelly, getIndicadoresLazo, getIndicadoresDatagas } from "./servicios";
import Highcharts from "highcharts";

//import Highcharts from 'highcharts/'
import moment from "moment";
import Storage from "util/store/Storage";
import Axios from "axios";
import server from "util/Server";
import { Alert, AlertTitle } from "@material-ui/lab";
import "./Alerta.css";

//indicadores
import IdentificadoresDashboard from "./componentes/indicadores/Indicadores";
import {
  getGraficaCombinada, getCreditosUltimos7Dias, getPromotoresMasVentasHoy,
  getConversionesUltimos7Dias, getVentasPorDias
} from "./serviciosGraficas"
import DataTableFilterDemo from "../../../components/Datatable";
import ProjectsList from "components/dashboard/Intranet/ProjectsList";
//otros componentes
import GraficoTorta from "./componentes/torta";
import GraficoIsla from "./componentes/isla";
import GraficoUltimosDiasCombustibles from "./componentes/ultimos7DiasCombustibles";
import GraficaVentaMesActual from "./componentes/VentaMesActual";
import VentasCombustibleXMes from "./componentes/VentasCombustibleXMes";
import {
  INDICADOR_FINANCIERAS,
  INDICADOR_DE_CREDITOS,
  INDICADOR_USUARIOS,
  INDICADOR_DE_COMPRAS,
  CONVERSIONES_ESTA_SEMANA,
  INDICADOR_ACUERDOS_EDS,
  INDICADOR_DE_CONVERSIONES,
  CREDITOS_ESTA_SEMANA,
  INDICADOR_VENTAS,
  INDICADOR_ESTACIONES,
  INDICADOR_PRODUCTOS,
  INDICADOR_VEHICULOS,
  INDICADOR_VENTAS_CANASTILLA,
  INDICADOR_VENTAS_FIDELIZADAS,
  COMBINADO,
  PROMOTORES_VENTAS_TOP,
  GRAFICA_TOP_DIAS_MAS_VENTAS,
  VENTA_COMBUSTIBLE_MES_ACTUAL,
  INDICADOR_VENTAS_MIXTAS
} from "../modulos_constant";
import { Logger, LoggerError } from "util/utils";
//PDF
import 'moment/locale/es'
moment.locale("es");
let graficoTorta = null;
let graficaUltimos7Dias = null;
let graficaMesActual = null;
let graficaUltimoMesCombustible = null;
let credito_7_dias = null
class Dashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      numTiendas: 0,
      clientes: 0,
      //mios
      total_empleados: 0,
      total_clientes: 0,
      total_estaciones: 0,
      total_ventas: 0,
      total_productos_combustible: 0,
      total_productos_canastilla: 0,
      total_valor_combustible: 0,
      total_vehiculos: 0,
      total_cantidad_combustible: 0,
      total_cantidad_mixtas: 0,
      total_valor_mixtas: 0,
      total_cantidad_compras: 0,
      ventas_vehiculos: 0,

      //grafica
      ventas_combustible_meses: [],
      ventas_ultimos_7_dias: [],
      ventas_por_mes_Actual: [],
      ventas_por_turnos_ultimos_7_dias: [],
      productos_mas_vendidos_hoy: [],
      // promotores_mas_ventas_hoy: [],
      estaciones_mas_ventas_hoy: [],
      total_valor_compras: [],
      torta_mas_ventas: [],
      tipoPromotoresTop: 0,
      topSeries: [],
      topCategories: [],
      topEstacionesSeries: [],
      topEstacionesCategories: [],
      vectorIds: [],

      //state para mostrar graficas
      ventas_por_turnos_ultimos_7_dias_show: false,
      promotores_mas_ventas_hoy_show: false,
      ventas_combustible_meses_show: false,
      ventas_por_mes_Actual_show: false,
      ventas_ultimos_7_dias_show: false,
      productos_mas_vendidos_hoy_show: false,
      ventas_por_isla_show: false,
      estaciones_mas_ventas_hoy_show: false,
      torta_mas_ventas_show: false,
      mostrarGraficas: [],
      promotores_mas_ventas_hoy: {},
      isShow: false,
      alerta: "",
      jornada_abierta: true,
      consecutivo: true,
      warning: true,
      ordenes_entrada: true,
      primeraCarga: true,
      refreshIntervalId: null,

      //alertas
      jornadas_abiertas: [],
      ordenes_compra: [],
      loaderGraficas: true,
      //
      checkTopPromotores: 2,
      consecutivos: [],
      //empanadas
      seMuestraTorta: false,
      //empreanadaempresa
      empresasConsultar: "",
      empresasSeleccionadas: [],
      empresasBD: [],

      permisos: "",
      conversiones_ultimos_7_dias: [],
      creditos_ultimos_7_dias: [],
      ocultarAlertas: false,

      //combinado
      dataSurtidoresCombinados: [],
      dataIslasCombinados: [],
      tiempoCombinado: "DIA",

      //secado virtual
      secado_virtual_datos: [],
      fechaActual: new Date(),

      //topPromotor
      tiempoTopPromotor: "DIA",

      //top ventas
      dataTopVentas: {},
      tiempoTopSemanal: "SEMANA",
    };
  }

  async componentWillMount() {
    const { usuario } = Storage.getState();
    //se obtienen las empresas
    this.setState({
      empresasBD: await getEmpresasHijos(),
      loader: false,
    });
    let TIPO_DOMINIO = ''
    try {
      TIPO_DOMINIO = usuario.dominio.atributos.tipo_dominio
    } catch (error) { }
    //fin de empresas
    if (TIPO_DOMINIO === "DATAGAS") {
      this.dashboardDatagas();
    } else {
      //indicador fuelly
      this.indicadoresFuelly();
      //fin infdicador fuelly
      this.obtenerComponenes();
      if (usuario.tipo === 3) {
        try {
          switch (usuario.empresa.atributos.tipo_negocio) {
            case "L":
            case "E":
              this.dashboardLazo();
              break;
            case "F":
            case "T":
              this.dashboardFuelly();
              this.obtenerComponenes();
              break;

            default:
              break;
          }
        } catch (error) { }
      } else if (usuario.tipo === 5) {
        try {
          switch (usuario.empresa.atributos.tipo_unidad) {
            case "T":
            case "F":
            case "V":
              this.dashboardFuelly();
              this.obtenerComponenes();
              break;
            case "E":
              this.dashboardLazo();
              this.obtenerComponenes();
              break;

            default:
              break;
          }
        } catch (error) { }
      }
    }

  }
  dashboardFuelly = () => {
    this.obtenerComponenes();
    this.indicadoresFuelly();
    this.conversiones_ultimos_7_dias_fuelly()
    this.creditos_ultimos_7dias_fuelly()
    this.graficoCombinada()

    this.setState({
      refreshIntervalId: setInterval(() => {
        this.setState({
          primeraCarga: false,
        });
        this.conversiones_ultimos_7_dias_fuelly();
        this.creditos_ultimos_7dias_fuelly();
        this.graficoCombinada()
      }, 20000)
    })
  };
  dashboardDatagas = async () => {

    this.indicadoresDatagas();

    //timer de graficas
    this.ventas_combustible_meses('_datagas');
    this.ventas_ultimos_7_dias('_datagas');

    this.setState({
      refreshIntervalId: setInterval(() => {
        this.indicadoresDatagas();
        this.ventas_combustible_meses('_datagas');
        this.ventas_ultimos_7_dias('_datagas');
      }, 20000)
    })

    //alertas
    setInterval(() => {
      setTimeout(() => {
        this.setState({
          consecutivo: true,
          ordenes_entrada: true,
          jornada_abierta: true,
          warning: true,
        });
      }, 6000);
    }, 9000);
  };

  dashboardLazo = async () => {

    this.obtenerJornadasAbiertas();
    this.obtenerComponenes();
    this.indicadoresLazo();
    this.obtenerOrdenCompra();
    this.obtenerConsecutivo();
    //timer de graficas
    this.ventas_combustible_meses();
    this.ventas_por_mes_Actual();
    this.ventas_ultimos_7_dias();
    this.productos_mas_vendidos_hoy();
    this.promotores_mas_ventas_hoy();
    this.estaciones_mas_ventas_hoy();
    this.torta_mas_ventas();
    this.graficoCombinada()
    this.getVentasPorDia()
    this.setState({
      secado_virtual_datos: await alertaSecadoVirtual()
    })
    this.setState({
      refreshIntervalId: setInterval(() => {
        if (this.state.mostrarGraficas.length === 7) {
          this.setState({
            primeraCarga: false,
          });
          this.indicadoresLazo();
          this.ventas_combustible_meses();
          this.ventas_por_mes_Actual();
          this.ventas_ultimos_7_dias();
          this.productos_mas_vendidos_hoy();
          this.promotores_mas_ventas_hoy();
          this.estaciones_mas_ventas_hoy();
          this.torta_mas_ventas();
          this.graficoCombinada()
          this.obtenerJornadasAbiertas();
          this.obtenerOrdenCompra();
          this.obtenerConsecutivo();
          this.getVentasPorDia()
        }
      }, 20000)
    })

    //alertas
    setInterval(() => {
      setTimeout(() => {
        this.setState({
          consecutivo: true,
          ordenes_entrada: true,
          jornada_abierta: true,
          warning: true,
        });
      }, 6000);
    }, 9000);
  };

  //imprimir

  imprimirMasVendidos = (estado, referencia, grafican, div, nombre) => {
    this.setState({
      [estado]: true
    })
    const nd = document.getElementById(div);
    let chart = this.refs[referencia].chartInstance
    var grafica = `<img width="520px" src="${chart.toBase64Image(chart)}"/>`
    document.getElementById(grafican).innerHTML = grafica
    var download = {
      "template": nd.innerHTML,
      "filename": nombre + ".pdf"
    }
    //console.log(download)
    Axios.post(server.HostPDF + server.puertoPdf + '/api/pdf/download', download)
      .then(response => {
        //console.log(response)
        var url = response.data.url
        var link = document.createElement('a');
        link.setAttribute("target", "_blank")
        link.download = response.data.filename;
        link.href = url;
        link.click();
        this.setState({
          [estado]: false
        })
      })
  }

  //din imprimir

  componentWillUnmount() {
    clearInterval(this.state.refreshIntervalId);
  }

  indicadoresLazo = async () => {
    var { usuario, empresasHijos } = Storage.getState();
    const sendFetch = {
      fecha_inicial: moment(new Date()).format("YYYY-MM-DD 00:00:00"),
      fecha_final: moment(new Date()).format("YYYY-MM-DD 24:00:00"),
      identificadorEstacion: empresasHijos,
      identificadorNegocio: usuario.empresas_id,
    };

    const {
      total_clientes,
      total_empleados,
      total_estaciones,
      total_cantidad_canastilla,
      total_productos_combustible,
      total_productos_canastilla,
      total_valor_canastilla,
      total_vehiculos,
      total_cantidad_compras,
      total_cantidad_combustible,
      total_valor_combustible,
      total_cantidad_mixtas,
      total_valor_mixtas,
      total_valor_compras,
      ventas_vehiculos
    } = await getIndicadoresLazo(sendFetch)
    this.setState({
      total_clientes,
      total_empleados,
      total_estaciones,
      total_cantidad_canastilla,
      total_productos_combustible,
      total_productos_canastilla,
      total_valor_canastilla,
      total_vehiculos,
      total_cantidad_compras,
      total_cantidad_combustible,
      total_valor_combustible,
      total_cantidad_mixtas,
      total_valor_mixtas,
      total_valor_compras,
      ventas_vehiculos
    });
  };
  //DASHBOARD FUELLY
  indicadoresFuelly = async () => {
    const { dominio, empresasHijos } = Storage.getState();
    //todo
    const sendFetch = {
      fecha_inicial: moment(new Date()).format("YYYY-MM-DD 00:00:00"),
      fecha_final: moment(new Date()).format("YYYY-MM-DD 24:00:00"),
      identificadorEstacion: empresasHijos,
      identificadorDominio: dominio,
    };
    const {
      total_conversiones,
      total_conversiones_aprobadas,
      total_finacieras,
      total_creditos,
      acuerdos_eds,
      total_creditos_aprobados,
      total_empleados,
      total_usuarios_financieras
    } = await getIndicadoresFuelly(sendFetch)
    this.setState({
      total_conversiones: total_conversiones,
      total_conversiones_aprobadas: total_conversiones_aprobadas,
      total_finacieras: total_finacieras,
      total_creditos: total_creditos,
      total_contratos_eds: acuerdos_eds,
      total_creditos_aprobados: total_creditos_aprobados,
      total_empleados: total_empleados,
      total_usuarios_financieras: total_usuarios_financieras,
    });
  };

  //DASHBOARD FUELLY
  indicadoresDatagas = async () => {
    const { negocio, empresasHijos } = Storage.getState();
    //todo
    const sendFetch = {
      fecha_inicial: moment(new Date()).format("YYYY-MM-DD 00:00:00"),
      fecha_final: moment(new Date()).format("YYYY-MM-DD 24:00:00"),
      identificadorEstacion: empresasHijos,
      identificadorNegocio: negocio,
    };
    const {
      total_empleados,
      total_estaciones,
      total_productos,
      total_valor_combustible,
      total_cantidad_ventas
    } = await getIndicadoresDatagas(sendFetch)
    this.setState({
      total_empleados,
      total_estaciones,
      total_productos_combustible: total_productos,
      total_cantidad_combustible: total_cantidad_ventas,
      total_valor_combustible
    });
  };

  conversiones_ultimos_7_dias_fuelly = async () => {
    const { empresasHijos } = Storage.getState();
    const data = {
      fecha_inicial: moment(new Date()).subtract("day", 6).format("YYYY-MM-DD 00:00:00"),
      fecha_final: moment(new Date()).format("YYYY-MM-DD 23:59:59"),
      identificadorEstacion: empresasHijos,
    };
    const ultimasConversiones = await getConversionesUltimos7Dias(data)
    ultimasConversiones.forEach((el) => {
      el.data.forEach((dat) => {
        if (dat !== 0) {
          this.setState({
            conversiones_ultimos_7_dias: ultimasConversiones,
            conversiones_ultimos_7_dias_show: true,
          });
          this.cargar7diasFuelly(ultimasConversiones);
        }
      });
    });
    this.setState({});
    if (this.state.primeraCarga) {
      this.state.mostrarGraficas.push(0);
    }
  };

  creditos_ultimos_7dias_fuelly = async () => {
    const { empresasHijos } = Storage.getState();
    const data = {
      fecha_inicial: moment(new Date())
        .subtract("day", 6)
        .format("YYYY-MM-DD 00:00:00"),
      fecha_final: moment(new Date()).format("YYYY-MM-DD 23:59:59"),
      identificadorEstacion: empresasHijos,
    };
    const ultimosCreditos = await getCreditosUltimos7Dias(data)
    this.setState({ ...this.state, creditos_ultimos_7_dias: ultimosCreditos });
    this.cargar7Creditos(ultimosCreditos)
    if (this.state.primeraCarga) {
      this.state.mostrarGraficas.push(0);
    }
  };

  //FIN PETICIONES FUELLY
  validarUsuario = () => {
    const { empresasHijos, usuario } = Storage.getState();
    const { empresasConsultar } = this.state;
    let empresa = "";
    if (usuario.tipo === 5) {
      empresa = usuario.empresas_id;
    } else if (empresasConsultar !== "") {
      empresa = empresasConsultar;
    } else {
      empresa = empresasHijos;
    }
    return empresa;
  };

  handleSelect = (name) => (value) => {
    this.setState({
      [name]: value,
    });
    switch (name) {
      case "empresasSeleccionadas":
        if (value != null) {
          let empresaEntregar = "";
          value.forEach((emp, k) => {
            if (k === 0) {
              empresaEntregar += emp.value;
            } else {
              empresaEntregar += "," + emp.value;
            }
          });
          this.setState({
            empresasConsultar: empresaEntregar,
          });
        }
        break;
      default:
        break;
    }
  };

  promotores_mas_ventas_hoy = async (tiempo) => {
    let { tiempoTopPromotor } = this.state
    let fechaInicial = ""
    let fechaFinal = ""
    if (tiempo) {
      tiempoTopPromotor = tiempo
    }
    switch (tiempoTopPromotor) {
      case "DIA":
        fechaInicial = moment(new Date()).format("YYYY-MM-DD 00:00:00")
        fechaFinal = moment(new Date()).format("YYYY-MM-DD 24:00:00")
        break;
      case "SEMANA":
        fechaInicial = moment().weekday(0).format("YYYY-MM-DD 00:00:00")
        fechaFinal = moment(new Date()).format("YYYY-MM-DD 24:00:00")
        break;
      case "MES":
        fechaInicial = moment().date(1).format("YYYY-MM-DD 00:00:00")
        fechaFinal = moment(new Date()).format("YYYY-MM-DD 24:00:00")
        break;
      case "ANO":
        fechaInicial = moment(new Date()).format("YYYY-01-01 00:00:00")
        fechaFinal = moment(new Date()).format("YYYY-MM-DD 24:00:00")
        break;
      default:
        break;
    }
    const data = {
      fecha_inicial: fechaInicial,
      fecha_final: fechaFinal,
      identificadorEstacion: this.validarUsuario(),
    };
    const promotoresMasVentas = await getPromotoresMasVentasHoy(data)
    if (promotoresMasVentas.total && promotoresMasVentas.total.categories && promotoresMasVentas.total.categories.length !== 0) {
      this.setState({
        promotores_mas_ventas_hoy_show: true,
      });
    }
    this.setState({
      promotores_mas_ventas_hoy: promotoresMasVentas,
      tipoPromotoresTop: promotoresMasVentas,
      checkTopPromotores: 2
    });
    if (this.state.primeraCarga) {
      this.state.mostrarGraficas.push(0);
    }
    this.cargarGraficaTopPromotores(
      promotoresMasVentas.total.categories,
      promotoresMasVentas.total.series,
    )
  };

  setearTiempoGraficas = (grafica, tiempo) => {
    this.setState({
      [grafica]: tiempo
    })
    switch (grafica) {
      case "tiempoTopPromotor":
        this.promotores_mas_ventas_hoy(tiempo)
        break;
      case "tiempoTopSemanal":
        this.getVentasPorDia(tiempo)
        break;
      case "tiempoCombinado":
        this.graficoCombinada(tiempo)
        break;

      default:
        break;
    }

  }

  productos_mas_vendidos_hoy = () => {
    const headers = {
      Authorization: server.obtenerToken(),
      versionapp: server.versionApp,
      versioncode: server.versionCode,
      fecha: moment().format(),
      aplicacion: server.aplicacion,
      identificadordispositivo: server.aplicacion,
    };
    const data = {
      fecha_inicial: moment(new Date()).format("YYYY-MM-DD 00:00:00"),
      fecha_final: moment(new Date()).format("YYYY-MM-DD 24:00:00"),
      identificadorEstacion: this.validarUsuario(),
    };
    Axios.post(server.host + server.puertoMovimiento + "/api/reportes/dashboard/productos_mas_vendidos_hoy", data, { headers: headers })
      .then((res) => {
        res.data.data.series.forEach((serie) => {
          if (serie.data.length === 0) {
            this.setState({
              productos_mas_vendidos_hoy_show: false,
            });
          } else {
            this.setState({
              productos_mas_vendidos_hoy: res.data.data,
              productos_mas_vendidos_hoy_show: true,
            });
          }
        });
        this.setState({});
        if (this.state.primeraCarga) {
          this.state.mostrarGraficas.push(0);
        }
      })
      .catch(() => {
        this.setState({
          productos_mas_vendidos_hoy: [],
          productos_mas_vendidos_hoy_show: false,
        });
      });
  };

  ventas_ultimos_7_dias = async (extra) => {
    extra = extra || ''
    const { negocio } = Storage.getState();

    const headers = {
      Authorization: server.obtenerToken(),
      versionapp: server.versionApp,
      versioncode: server.versionCode,
      fecha: moment().format(),
      aplicacion: server.aplicacion,
      identificadordispositivo: server.aplicacion,
    };
    const data = {
      fecha_inicial: moment(new Date()).subtract("day", 6).format("YYYY-MM-DD 00:00:00"),
      fecha_final: moment(new Date()).format("YYYY-MM-DD 24:00:00"),
      identificadorEstacion: this.validarUsuario(),
      identificadorNegocio: negocio
    };

    await Axios.post(server.host + server.puertoMovimiento + "/api/reportes/dashboard/ventas_ultimos_7_dias" + extra, data, { headers: headers, })
      .then((res) => {
        res.data.data.forEach((el) => {
          el.data.forEach((dat) => {
            if (dat !== 0) {
              this.setState({
                ventas_ultimos_7_dias: res.data.data,
                ventas_ultimos_7_dias_show: true,
              });
              this.cargar7dias(res.data.data, extra === '_datagas');
            }
          });
        });
        if (this.state.primeraCarga) {
          this.state.mostrarGraficas.push(0);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  cargar7Creditos = async (data) => {
    var { vectorIds } = this.state;
    if (vectorIds.includes(CREDITOS_ESTA_SEMANA)) {
      credito_7_dias = null;
      credito_7_dias = <GraficoUltimosDiasCombustibles data={data} label="CREDITOS ULTIMOS 7 DIAS" add="" />
    }
  };
  cargar7diasFuelly = async (data) => {
    var { vectorIds } = this.state;
    if (vectorIds.includes(CONVERSIONES_ESTA_SEMANA)) {
      graficaUltimos7Dias = null;
      graficaUltimos7Dias = <GraficoUltimosDiasCombustibles data={data} label="CONVERSIONES ULTIMOS 7 DIAS" add="" />
    }
  };

  cargar7dias = async (data, datagas) => {
    var { vectorIds } = this.state;
    if (vectorIds.includes(VENTA_COMBUSTIBLE_MES_ACTUAL) || datagas) {
      graficaUltimos7Dias = null;
      graficaUltimos7Dias = await (
        <GraficoUltimosDiasCombustibles data={data} label="VENTA DE COMBUSTIBLE ULTIMOS 7 DIAS" add="$" />
      );
    }
  };
  cargarMesActual = async (data) => {
    const { vectorIds } = this.state;
    if (vectorIds.includes(VENTA_COMBUSTIBLE_MES_ACTUAL)) {
      graficaMesActual = null;
      graficaMesActual = await (
        <GraficaVentaMesActual categories={data.dia} series={data.data} />
      );
    }
  };

  ventas_por_mes_Actual = () => {
    const headers = {
      Authorization: server.obtenerToken(),
      versionapp: server.versionApp,
      versioncode: server.versionCode,
      fecha: moment().format(),
      aplicacion: server.aplicacion,
      identificadordispositivo: server.aplicacion,
    };
    const data = {
      fecha_inicial: moment(new Date()).format("YYYY-MM-DD 00:00:00"),
      fecha_final: moment(new Date()).format("YYYY-MM-DD 24:00:00"),
      identificadorEstacion: this.validarUsuario(),
    };

    Axios.post(server.host + server.puertoMovimiento + "/api/reportes/dashboard/ventas_por_mes_Actual", data, { headers: headers })
      .then((res) => {
        res.data.data.data.forEach((dat) => {
          if (dat !== 0) {
            this.setState({
              ventas_por_mes_Actual: res.data.data,
              ventas_por_mes_Actual_show: true,
            });
            this.cargarMesActual(res.data.data);
          }
        });
        if (this.state.primeraCarga) {
          this.state.mostrarGraficas.push(0);
        }
      })
      .catch(() => {
        this.setState({
          ventas_por_mes_Actual_show: false,
        });
      });
  };

  ventas_por_turnos_ultimos_7_dias = async () => {
    const headers = {
      Authorization: server.obtenerToken(),
      versionapp: server.versionApp,
      versioncode: server.versionCode,
      fecha: moment().format(),
      aplicacion: server.aplicacion,
      identificadordispositivo: server.aplicacion,
    };
    const data = {
      fecha_inicial: moment(new Date())
        .subtract("day", 6)
        .format("YYYY-MM-DD 00:00:00"),
      fecha_final: moment(new Date()).format("YYYY-MM-DD 24:00:00"),
      identificadorEstacion: this.validarUsuario(),
    };

    try {
      const res = await Axios.post(
        server.host +
        server.puertoMovimiento +
        "/api/reportes/dashboard/ventas_por_turnos_ultimos_7_dias",
        data,
        { headers: headers }
      );
      this.setState({
        ventas_por_turnos_ultimos_7_dias: res.data.data,
        ventas_ultimos_7_dias_show: true,
      });
      if (this.state.primeraCarga) {
        this.state.mostrarGraficas.push(0);
      }
    } catch (error) {
      this.setState({
        ventas_por_turnos_ultimos_7_dias: [],
        ventas_ultimos_7_dias_show: false,
      });
    }
  };

  ventas_combustible_meses = async (extra) => {
    extra = extra || ''
    const { negocio } = Storage.getState();
    const headers = {
      Authorization: server.obtenerToken(),
      versionapp: server.versionApp,
      versioncode: server.versionCode,
      fecha: moment().format(),
      aplicacion: server.aplicacion,
      identificadordispositivo: server.aplicacion,
    };
    let año = moment().format("YYYY");
    const data = {
      fecha_inicial: año + "-01-01 00:00:00",
      fecha_final: moment(new Date()).format("YYYY-MM-DD 24:00:00"),
      identificadorEstacion: this.validarUsuario(),
      identificadorNegocio: negocio
    };

    Axios.post(
      server.host +
      server.puertoMovimiento +
      "/api/reportes/dashboard/ventas_combustible_meses" + extra,
      data,
      { headers: headers }
    )
      .then((res) => {
        res.data.data.series.forEach((el) => {
          el.data.forEach((dat) => {
            if (dat !== 0) {
              this.setState({
                ventas_combustible_meses: res.data.data,
                ventas_combustible_meses_show: true,
              });
              this.cargarGraficasMeses(res.data.data);
            }
          });
        });

        if (this.state.primeraCarga) {
          this.state.mostrarGraficas.push(0);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  cargarGraficasMeses = (data) => {
    graficaUltimoMesCombustible = null;
    setTimeout(() => {
      graficaUltimoMesCombustible = (
        <VentasCombustibleXMes
          categories={data.categories}
          series={data.series}
        />
        , 100);
    })
  };

  estaciones_mas_ventas_hoy = async () => {
    const headers = {
      Authorization: server.obtenerToken(),
      versionapp: server.versionApp,
      versioncode: server.versionCode,
      fecha: moment().format(),
      aplicacion: server.aplicacion,
      identificadordispositivo: server.aplicacion,
    };
    const datos = {
      fecha_inicial: moment(new Date())
        .subtract("day", 6)
        .format("YYYY-MM-DD 00:00:00"),
      fecha_final: moment(new Date()).format("YYYY-MM-DD 24:00:00"),
      identificadorEstacion: this.validarUsuario(),
    };

    try {
      const res = await Axios.post(
        server.host +
        server.puertoMovimiento +
        "/api/reportes/dashboard/estaciones_mas_ventas_hoy",
        datos,
        { headers: headers }
      );
      if (res.data.data.total.categories.length === 0) {
        this.setState({
          estaciones_mas_ventas_hoy_show: false,
        });
      } else {
        this.setState({
          estaciones_mas_ventas_hoy: res.data.data,
          topEstacionesSeries: res.data.data.total.series,
          topEstacionesCategories: res.data.data.total.categories,
          estaciones_mas_ventas_hoy_show: true,
        });
      }
      if (this.state.primeraCarga) {
        this.state.mostrarGraficas.push(0);
      }
    } catch (error) {
      if (error.message === "Network Error") {
        Swal.fire(error.message, "POR FAVOR RECARGUE LA PÁGINA", "warning");
      }
    }
  };

  torta_mas_ventas = () => {
    const { negocio } = Storage.getState();
    const headers = {
      Authorization: server.obtenerToken(),
      versionapp: server.versionApp,
      versioncode: server.versionCode,
      fecha: moment().format(),
      aplicacion: server.aplicacion,
      identificadordispositivo: server.aplicacion,
    };
    const data = {
      fecha_inicial: moment(new Date())
        .subtract("day", 6)
        .format("YYYY-MM-DD 00:00:00"),
      fecha_final: moment(new Date()).format("YYYY-MM-DD 24:00:00"),
      identificadorEstacion: this.validarUsuario(),
      identificadorNegocio: negocio
    };

    Axios.post(
      server.host + server.puertoMovimiento + "/api/reportes/dashboard/borrar",
      data,
      { headers: headers }
    )
      .then((res) => {
        res.data.data.reporteAnual.forEach((reporte) => {
          reporte.data.forEach((anual) => {
            if (anual.y !== 0) {
              this.setState({
                torta_mas_ventas: res.data.data,
                topTortasSeries: res.data.data.reporteDiario,
                torta_mas_ventas_show: true,
              });
            }
          });
        });
        this.setState({
          torta_mas_ventas: res.data.data,
          topTortasSeries: res.data.data.reporteDiario,
        });
        if (this.state.primeraCarga) {
          this.state.mostrarGraficas.push(0);
        }
        if (
          this.state.mostrarGraficas.length === 7 &&
          this.state.loaderGraficas
        ) {
          this.crearGraficas();
        }
      })
      .catch((error) => {
        console.log(error);
        if (this.state.primeraCarga) {
          this.state.mostrarGraficas.push(0);
        }
      });
  };

  graficoCombinada = async (tiempo) => {
    const { negocio } = Storage.getState();
    let { tiempoCombinado } = this.state
    let fechaInicial = ""
    let fechaFinal = ""
    if (tiempo) {
      tiempoCombinado = tiempo
    }
    switch (tiempoCombinado) {
      case "DIA":
        fechaInicial = moment(new Date()).format("YYYY-MM-DD 00:00:00")
        fechaFinal = moment(new Date()).format("YYYY-MM-DD 24:00:00")
        break;
      case "SEMANA":
        fechaInicial = moment().weekday(0).format("YYYY-MM-DD 00:00:00")
        fechaFinal = moment(new Date()).format("YYYY-MM-DD 24:00:00")
        break;
      case "MES":
        fechaInicial = moment().date(1).format("YYYY-MM-DD 00:00:00")
        fechaFinal = moment(new Date()).format("YYYY-MM-DD 24:00:00")
        break;
      case "ANO":
        fechaInicial = moment(new Date()).format("YYYY-01-01 00:00:00")
        fechaFinal = moment(new Date()).format("YYYY-MM-DD 24:00:00")
        break;
      default:
        break;
    }
    const data = {
      fecha_inicial: fechaInicial,
      fecha_final: fechaFinal,
      identificadorEstacion: this.validarUsuario(),
      identificadorNegocio: negocio
    };
    const { islas, surtidores } = await getGraficaCombinada(data)
    this.setState({
      dataIslasCombinados: islas,
      dataSurtidoresCombinados: surtidores
    });
    this.cargarGraficoCombinadas()
  };

  getVentasPorDia = async (tiempo) => {
    let { tiempoTopSemanal } = this.state
    let fechaInicial = ""
    let fechaFinal = ""
    if (tiempo) {
      tiempoTopSemanal = tiempo
    }
    switch (tiempoTopSemanal) {
      case "SEMANA":
        fechaInicial = moment(new Date()).subtract("day", 6).format("YYYY-MM-DD 00:00:00")
        fechaFinal = moment(new Date()).format("YYYY-MM-DD 24:00:00")
        break;
      case "MES":
        fechaInicial = moment().date(1).format("YYYY-MM-DD 00:00:00")
        fechaFinal = moment(new Date()).format("YYYY-MM-DD 24:00:00")
        break;
      case "ANO":
        fechaInicial = moment(new Date()).format("YYYY-01-01 00:00:00")
        fechaFinal = moment(new Date()).format("YYYY-MM-DD 24:00:00")
        break;
      default:
        break;
    }
    const data = {
      fecha_inicial: fechaInicial,
      fecha_final: fechaFinal,
      identificadorEstacion: this.validarUsuario()
    };
    const ventas = await getVentasPorDias(data)
    this.setState({
      dataTopVentas: ventas
    })
    this.cargarGraficoTopSemanal()
  };

  cargarGraficoCombinadas = () => {
    const { vectorIds, dataIslasCombinados, dataSurtidoresCombinados } = this.state
    try {
      if (vectorIds.includes(COMBINADO)) {
        Highcharts.chart("mixto", {
          title: {
            text: "",
          },
          xAxis: {
            categories: dataIslasCombinados,
          },
          tooltip: {
            formatter: function () {
              return ' $' + setearMiles(this.point.y);
            }
          },
          labels: {
            items: [
              {
                html: "VENTAS POR PRODUCTOS",
                style: {
                  left: "50px",
                  top: "18px",
                  color:
                    (Highcharts.defaultOptions.title.style &&
                      Highcharts.defaultOptions.title.style.color) ||
                    "black",
                },
              },
            ],
          },
          series: Array.isArray(dataSurtidoresCombinados) ? dataSurtidoresCombinados.filter(value => value !== null) : [],
        });
      }
    } catch (err) {
      LoggerError(err, 'cargarGraficoCombinadas', 'Dashboard')
    }

  }

  cargarGraficoTopSemanal = () => {
    const { vectorIds, dataTopVentas } = this.state
    if (vectorIds.includes(GRAFICA_TOP_DIAS_MAS_VENTAS)) {
      Highcharts.chart("top-ventas", {
        chart: {
          type: "bar",
        },
        title: {
          text: "",
        },
        tooltip: {
          formatter: function () {
            return ' $ ' + setearMiles(this.point.y);
          }
        },
        xAxis: {
          categories: dataTopVentas.categories,
        },
        yAxis: {
          min: 0,
          title: {
            text: "TOTAL VENTAS",
          },
        },
        legend: {
          reversed: true,
        },
        plotOptions: {
          series: {
            stacking: "normal",
          },
        },
        series: [{
          name: "ventas",
          data: dataTopVentas.series
        }],
      });
    }
  }

  obtenerComponenes = () => {
    var { usuario } = Storage.getState();
    var modulos = usuario.modulos;
    var ids = [];

    if (modulos != null) {
      modulos.forEach((mod) => {
        ids.push(mod.id);
      });

      this.setState({
        vectorIds: ids,
      });
    }
  };

  setearPromotoresTop = (index) => {
    let topPromotoresCategorias = [];
    let topPromotoresSeries = [];
    switch (index) {
      case 0:
        topPromotoresCategorias = this.state.promotores_mas_ventas_hoy
          .canastilla.categories;
        topPromotoresSeries = this.state.promotores_mas_ventas_hoy.canastilla
          .series;
        break;
      case 1:
        topPromotoresCategorias = this.state.promotores_mas_ventas_hoy
          .combustible.categories;
        topPromotoresSeries = this.state.promotores_mas_ventas_hoy.combustible
          .series;
        break;
      case 2:
        topPromotoresCategorias = this.state.promotores_mas_ventas_hoy.total
          .categories;
        topPromotoresSeries = this.state.promotores_mas_ventas_hoy.total.series;
        break;

      default:
        topPromotoresCategorias = this.state.promotores_mas_ventas_hoy.total
          .categories;
        topPromotoresSeries = this.state.promotores_mas_ventas_hoy.total.series;
        break;
    }
    this.setState({
      topSeries: topPromotoresSeries,
      topCategories: topPromotoresCategorias,
      checkTopPromotores: index,
      tipoPromotoresTop: index,
    });
    //llamar tabla otra vez
    this.cargarGraficaTopPromotores(topPromotoresCategorias, topPromotoresSeries)
  };

  cargarGraficaTopPromotores = (topPromotoresCategorias, topPromotoresSeries) => {
    var { vectorIds } = this.state;
    if (vectorIds.includes(PROMOTORES_VENTAS_TOP)) {
      Highcharts.chart("ventas-x-promotor-top", {
        chart: {
          type: "bar",
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: topPromotoresCategorias,
        },
        yAxis: {
          min: 0,
          title: {
            text: "TOTAL VENTAS",
          },
        },
        legend: {
          reversed: true,
        },
        plotOptions: {
          series: {
            stacking: "normal",
          },
        },
        series: topPromotoresSeries,
      });
    }
  }

  setVentasTorta = (index) => {
    var { vectorIds } = this.state;
    let topTortasSeries = [];
    switch (index) {
      case 0:
        topTortasSeries = this.state.torta_mas_ventas.reporteDiario;
        break;
      case 1:
        topTortasSeries = this.state.torta_mas_ventas.reporteSemanal;
        break;
      case 2:
        topTortasSeries = this.state.torta_mas_ventas.reporteMensual;
        break;
      case 3:
        topTortasSeries = this.state.torta_mas_ventas.reporteAnual;
        break;
      default:
        topTortasSeries = this.state.torta_mas_ventas.reporteDiario;
        break;
    }
    this.setState({
      topSeriesTorta: topTortasSeries,
    });
    //llamar tabla otra vez
    if (vectorIds.includes(18)) {
      graficoTorta = <GraficoTorta data={this.state.torta_mas_ventas} />;
    }
  };

  setTopEstaciones = (index) => {
    var { vectorIds } = this.state;
    let topEstacionesSeries = [];
    let topEstacionesCategories = [];
    switch (index) {
      case 0:
        topEstacionesSeries = this.state.estaciones_mas_ventas_hoy.combustible
          .series;
        topEstacionesCategories = this.state.estaciones_mas_ventas_hoy
          .combustible.categories;
        break;
      case 1:
        topEstacionesSeries = this.state.estaciones_mas_ventas_hoy.canastilla
          .series;
        topEstacionesCategories = this.state.estaciones_mas_ventas_hoy
          .canastilla.categories;
        break;
      case 2:
        topEstacionesSeries = this.state.estaciones_mas_ventas_hoy.total.series;
        topEstacionesCategories = this.state.estaciones_mas_ventas_hoy.total
          .categories;
        break;
      default:
        topEstacionesSeries = this.state.estaciones_mas_ventas_hoy.total.series;
        topEstacionesCategories = this.state.estaciones_mas_ventas_hoy.total
          .categories;
        break;
    }
    this.setState({
      topEstacionesSeries: topEstacionesSeries,
      topEstacionesCategories: topEstacionesCategories,
    });
    //llamar tabla otra vez
    if (vectorIds.includes(18)) {
      Highcharts.chart("ventas-x-estacion-top", {
        chart: {
          type: "bar",
        },
        title: {
          text: "TOP 10 ESTACIONES QUE MAS VENDIERON (HOY)",
        },
        xAxis: {
          categories: topEstacionesCategories,
        },
        yAxis: {
          min: 0,
          title: {
            text: "TOTAL VENTAS",
          },
        },
        legend: {
          reversed: true,
        },
        plotOptions: {
          series: {
            stacking: "normal",
          },
        },
        series: topEstacionesSeries,
      });
    }
  };

  crearGraficas = async () => {
    var { vectorIds, tipoPromotoresTop } = this.state;
    let topPromotoresCategorias = [];
    let topPromotoresSeries = [];
    switch (tipoPromotoresTop) {
      case 0:
        topPromotoresCategorias = this.state.promotores_mas_ventas_hoy
          .canastilla.categories;
        topPromotoresSeries = this.state.promotores_mas_ventas_hoy.canastilla
          .series;
        break;
      case 1:
        topPromotoresCategorias = this.state.promotores_mas_ventas_hoy
          .combustible.categories;
        topPromotoresSeries = this.state.promotores_mas_ventas_hoy.combustible
          .series;
        break;
      case 2:
        topPromotoresCategorias = this.state.promotores_mas_ventas_hoy.total
          .categories;
        topPromotoresSeries = this.state.promotores_mas_ventas_hoy.total.series;
        break;

      default:
        topPromotoresCategorias = this.state.promotores_mas_ventas_hoy.total
          .categories;
        topPromotoresSeries = this.state.promotores_mas_ventas_hoy.total.series;
        break;
    }
    if (vectorIds.includes(8)) {
      Highcharts.chart("ventas-x-rangos-horas", {
        chart: {
          type: "bar",
        },
        title: {
          text: "VENTAS POR TURNOS EN LOS ULTIMOS 7 DIAS",
        },
        xAxis: {
          categories: [
            moment()
              .subtract(1, "d")
              .format("dddd DD/MM"),
            moment()
              .subtract(2, "d")
              .format("dddd DD/MM"),
            moment()
              .subtract(3, "d")
              .format("dddd DD/MM"),
            moment()
              .subtract(4, "d")
              .format("dddd DD/MM"),
            moment()
              .subtract(5, "d")
              .format("dddd DD/MM"),
            moment()
              .subtract(6, "d")
              .format("dddd DD/MM"),
            moment().format("dddd DD/MM"),
          ],
        },
        yAxis: {
          min: 0,
          title: {
            text: "TOTAL VENTAS",
          },
        },
        legend: {
          reversed: true,
        },
        plotOptions: {
          series: {
            stacking: "normal",
          },
        },
        series: this.state.ventas_por_turnos_ultimos_7_dias,
      });
    }
    if (vectorIds.includes(PROMOTORES_VENTAS_TOP)) {
      Highcharts.chart("ventas-x-promotor-top", {
        chart: {
          type: "bar",
        },
        title: {
          text: "",
        },
        tooltip: {
          formatter: function () {
            return '<b>' + this.point.category + '</b>: $ ' + setearMiles(this.point.y);
          }
        },
        xAxis: {
          categories: topPromotoresCategorias,
        },
        yAxis: {
          min: 0,
          title: {
            text: "TOTAL VENTAS",
          },
        },
        legend: {
          reversed: true,
        },
        plotOptions: {
          series: {
            stacking: "normal",
          },
        },
        series: topPromotoresSeries,
      });
    }
    if (vectorIds.includes(26)) {
      Highcharts.chart("ventas-x-promotor", {
        chart: {
          type: "bar",
        },
        title: {
          text: "VENTAS POR PROMOTOR (HOY) (ESTACION)",
        },
        xAxis: {
          categories: [
            "PROMOTOR 1",
            "PROMOTOR 2",
            "PROMOTOR 3",
            "PROMOTOR 4",
            "PROMOTOR 5",
          ],
        },
        yAxis: {
          min: 0,
          title: {
            text: "TOTAL VENTAS",
          },
        },
        legend: {
          reversed: true,
        },
        plotOptions: {
          series: {
            stacking: "normal",
          },
        },
        series: [
          {
            name: "VENTAS EN ($)",
            data: [20800, 14750, 28515, 24124, 32818],
            color: "green",
          },
        ],
      });
    }
    if (vectorIds.includes(10)) {
      graficaMesActual = null;
      graficaMesActual = (
        <GraficaVentaMesActual
          categories={this.state.ventas_por_mes_Actual.dia}
          series={this.state.ventas_por_mes_Actual.data}
        />
      );
    }
    if (vectorIds.includes(11)) {
      Highcharts.chart("ventas-x-estacion-top", {
        chart: {
          type: "bar",
        },
        title: {
          text: "TOP 10 ESTACIONES QUE MAS VENDIERON (HOY)",
        },
        xAxis: {
          categories: this.state.topEstacionesCategories,
        },
        yAxis: {
          min: 0,
          title: {
            text: "TOTAL VENTAS",
          },
        },
        legend: {
          reversed: true,
        },
        plotOptions: {
          series: {
            stacking: "normal",
          },
        },
        series: this.state.topEstacionesSeries,
      });
    }
    if (vectorIds.includes(12)) {
      Highcharts.chart("ventas-x-producto-top", {
        chart: {
          type: "bar",
        },
        title: {
          text: "TOP 10 PRODUCTOS MÁS VENDIDOS (HOY)",
        },
        xAxis: {
          categories: this.state.productos_mas_vendidos_hoy.categories,
        },
        yAxis: {
          min: 0,
          title: {
            text: "TOTAL VENTAS",
          },
        },
        legend: {
          reversed: true,
        },
        plotOptions: {
          series: {
            stacking: "normal",
          },
        },
        series: this.state.productos_mas_vendidos_hoy.series,
      });
    }
    if (vectorIds.includes(13)) {
      graficaUltimoMesCombustible = null;
      graficaUltimoMesCombustible = (
        <VentasCombustibleXMes
          categories={this.state.ventas_combustible_meses.categories}
          series={this.state.ventas_combustible_meses.series}
        />
      );
    }
    if (vectorIds.includes(COMBINADO)) {
      this.cargarGraficoCombinadas()
    }
    if (vectorIds.includes(18)) {
      graficoTorta = null;
      graficoTorta = <GraficoTorta data={this.state.torta_mas_ventas} />;
    }
  };
  detallesConsecutiva = () => {
    return (
      <div className="mx-auto">
        <DataTableFilterDemo
          paginator={true}
          rows={10}
          data={this.state.consecutivos}
          columns={[
            {
              field: "prefijo",
              header: "PREFIJO",
            },
            {
              field: "resolucion",
              header: "RESOLUCION",
            },
            {
              field: "fecha_inicio",
              header: "FECHA INICIAL",
            },
            {
              field: "fecha_fin",
              header: "FECHA FINAL",
            },
            {
              field: "consecutivo_inicial",
              header: "CONS. INICIAL",
              number: true,
            },
            {
              field: "consecutivo_actual",
              header: "CONS. ACTUAL",
              number: true,
            },
            {
              field: "consecutivo_final",
              header: "CONS. FINAL",
              number: true,
            },
            {
              field: "consecutivo_restante",
              header: "CONS. RESTANTES",
            },
          ]}
        />
      </div>
    );
  };
  detallesJordanaActiva = () => {
    return (
      <div className="col-lg-12 col-12">
        <div className="jr-card">
          <ProjectsList data={this.state.jornadas_abiertas} />
        </div>
      </div>
    );
  };
  detallesCompraAbierta = () => {
    return (
      <div className="row mx-auto">
        <DataTableFilterDemo
          paginator={true}
          rows={10}
          data={this.state.pruebas}
          columns={[
            {
              field: "consecutivo",
              header: "CONSECUTIVO",
            },
            {
              field: "fecha",
              header: "FECHA",
            },
            {
              field: "responsable",
              header: "RESPONSABLE",
            },
            {
              field: "proovedor",
              header: "PROOVEDOR",
            },
            {
              field: "costo_total",
              header: "TOTAL",
            },
          ]}
        />
      </div>
    );
  };
  detallesSecadoVirtual = () => {
    return (
      <div className="row mx-auto">
        <DataTableFilterDemo
          paginator={true}
          rows={10}
          data={this.state.secado_virtual_datos}
          columns={[
            {
              field: "bodega",
              header: "BODEGA",
            },
            {
              field: "codigo",
              header: "CODIGO",
            },
            {
              field: "descripcion",
              header: "DESCRIPCION",
            },
            {
              field: "saldo",
              header: "SALDO",
            },
            {
              field: "volumen_minimo",
              header: "VOLUMEN MINIMO",
            },
          ]}
        />
      </div>

    );
  };

  modalAlertas = (title) => {
    this.setState({
      [title]: false,
      isShow: true,
      alerta: title,
    });
  };
  obtenerJornadasAbiertas = async () => {
    const headers = {
      Authorization: server.obtenerToken(),
      versionapp: server.versionApp,
      versioncode: server.versionCode,
      fecha: moment().format(),
      aplicacion: server.aplicacion,
      identificadordispositivo: server.aplicacion,
    };
    const data = {
      fecha_inicial: moment(new Date()).format("YYYY-MM-DD 00:00:00"),
      fecha_final: moment(new Date()).format("YYYY-MM-DD 24:00:00"),
      identificadorEstacion: this.validarUsuario(),
    };
    try {
      const res = await Axios.post(
        server.host +
        server.puertoMovimiento +
        "/api/reportes/dashboard/alerta_jornadas_abiertas",
        data,
        { headers: headers }
      );
      let porcentaje = calcularPorcentaje(
        res.data.data.map((dat) => setearMiles(dat.num_ventas))
      );
      const datos = [];
      let porcen = 0;
      res.data.data.forEach((dat, i) => {
        porcen = porcentaje[i];
        datos.push({
          ...dat,
          porcentaje: porcen,
          hora: moment(dat.fecha_inicio, 'YYYY-MM-DDTHH:mm:ss').format('MMMM DD YYYY, h:mm A').toUpperCase()
        });
      });
      this.setState({
        jornadas_abiertas: datos,
      });
    } catch (error) {
      console.log(error);
    }
  };
  obtenerConsecutivo = async () => {
    const headers = {
      Authorization: server.obtenerToken(),
      versionapp: server.versionApp,
      versioncode: server.versionCode,
      fecha: moment().format(),
      aplicacion: server.aplicacion,
      identificadordispositivo: server.aplicacion,
    };
    const data = {
      fecha_inicial: moment(new Date()).format("YYYY-MM-DD 00:00:00"),
      fecha_final: moment(new Date()).format("YYYY-MM-DD 24:00:00"),
      identificadorEstacion: this.validarUsuario(),
    };
    try {
      const res = await Axios.post(
        server.host +
        server.puertoMovimiento +
        "/api/reportes/dashboard/alerta_consecutivos",
        data,
        { headers: headers }
      );
      let data1 = [];
      res.data.data.forEach((dat) => {
        data1.push({
          ...dat,
          fecha_inicio: moment(dat.fecha_inicio).format("DD/MM/YYYY"),
          fecha_fin: moment(dat.fecha_fin).format("DD/MM/YYYY"),
          consecutivo_restante:
            parseInt(dat.consecutivo_final) - parseInt(dat.consecutivo_actual),

        });
      });
      this.setState({
        consecutivos: data1,
      });
    } catch (error) {
      console.log(error);
    }
  };
  obtenerOrdenCompra = async () => {
    const { empresasHijos } = Storage.getState();
    try {
      const res = await Axios.get(
        server.host +
        server.puertoCentury +
        "/api/movimiento/orden-compra-activa/" +
        empresasHijos,
        { headers: { Authorization: 'Bearer '.concat(localStorage.getItem('token')) } }
      );
      const data = [];
      res.data.mensaje.map((dat) => (
        data.push({
          dat,
          fecha: moment(dat.fecha).format("YYYY-MM-DD"),
          costo_total: "$ " + setearMiles(dat.costo_total),
        })
          (dat.fecha = moment(dat.fecha).format("YYYY-MM-DD"))
          (dat.costo_total = "$ " + setearMiles(dat.costo_total))
      ));
      this.setState({
        ordenes_compra: res.data.mensaje,
      });
    } catch (error) {
      // console.log(error);
    }
  };


  render() {
    const {
      vectorIds,
      total_cantidad_canastilla,
      total_estaciones,
      total_clientes,
      total_productos_canastilla,
      total_productos_combustible,
      total_valor_canastilla,
      total_vehiculos,
      total_cantidad_compras,
      total_cantidad_combustible,
      total_valor_combustible,
      total_cantidad_mixtas,
      total_valor_mixtas,
      total_valor_compras,
      ventas_vehiculos,
      //alertas
      jornadas_abiertas,
      consecutivos,
      ordenes_compra,
      secado_virtual_datos,
      tiempoTopPromotor,
      checkTopPromotores,
      tiempoTopSemanal,
      tiempoCombinado
    } = this.state;
    const { usuario, dominio } = Storage.getState();
    let parametros_dominio = {}
    let parametros_negocio = {}
    let TIPO_DOMINIO = ''
    try {
      parametros_dominio = usuario.dominio.parametros
      parametros_negocio = usuario.parametros_negocio
      TIPO_DOMINIO = usuario.dominio.atributos.tipo_dominio
    } catch (error) {

    }
    return (
      <div
        style={{ padding: "15px" }}
        className="dashboard animated slideInUpTiny animation-duration-3"
      >
        <ContainerHeader
          match={{
            path: "/app/dashboard",
            url: "/app/dashboard",
          }}
          title={"Inicio"}
        />

        {/**FILTRADO */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>FILTROS</Typography>
          </AccordionSummary>
          <div className="row" style={{ padding: "10px" }}>
            <div className="col-md-8 col-12">
              <label htmlFor="tipo">EMPRESA</label>
              <Selects
                isMulti
                placeholder="SELECCIONE LA EMPRESA"
                value={this.state.empresasSeleccionadas}
                onChange={this.handleSelect("empresasSeleccionadas")}
                options={this.state.empresasBD}
              />
            </div>
            <div className="col-md-4 col-sm-4 mt-2">
              <button
                className="btn btn-primary btn-block mt-4"
                onClick={() => this.dashboardLazo()}
              >
                BUSCAR
              </button>
            </div>
          </div>
        </Accordion>

        {/**indicadores  datagas*/}
        {
          TIPO_DOMINIO === 'DATAGAS' &&
          (
            <div className="row">

              <IdentificadoresDashboard
                icon={`${server.hostMultimedia}/${dominio}/indicadores/usuarios.png`}
                subtitulo="EMPLEADOS "
                titulo="USUARIOS"
                descripcionAbajo=""
                datoArriba={this.state.total_empleados}
                datoAbajo={this.state.total_empleados}
                color="#52c41a"
                tipo={true}
                imgDefault={usuarioImg}
              />

              <IdentificadoresDashboard
                icon={`${server.hostMultimedia}/${dominio}/indicadores/ventasCombustibles.png`}
                subtitulo="VENTAS REALIZADAS"
                titulo="VENTAS COMBUSTIBLE"
                descripcionAbajo="TOTAL EN VENTAS"
                datoArriba={total_cantidad_combustible}
                datoAbajo={total_valor_combustible}
                color="#4caf50"
                imgDefault={ventasCombustiblesImg}
              />

              <IdentificadoresDashboard
                icon={`${server.hostMultimedia}/${dominio}/indicadores/estaciones.png`}
                subtitulo="ESTACIONES"
                titulo="ESTACIONES"
                descripcionAbajo="VENTAS EN ESTACIONES"
                datoArriba={total_estaciones}
                datoAbajo={total_valor_combustible}
                color="#666666"
                imgDefault={estacion}
              />
            </div>
          )
        }
        {/**indicadores */}
        <div className="row">

          {vectorIds.includes(INDICADOR_VENTAS_CANASTILLA) ? (
            <IdentificadoresDashboard
              icon={ventasCanastillaImg}
              subtitulo="VENTAS REALIZADAS"
              titulo="VENTAS COMPLEMENTARIAS"
              descripcionAbajo="Total de ventas"
              datoArriba={total_cantidad_canastilla}
              datoAbajo={total_valor_canastilla}
              color="#ff7f00"
            />
          ) : null}

          {vectorIds.includes(INDICADOR_VENTAS) && (
            <IdentificadoresDashboard
              icon={`${server.hostMultimedia}/${dominio}/indicadores/ventasCombustibles.png`}
              subtitulo="VENTAS REALIZADAS"
              titulo="VENTAS COMBUSTIBLE"
              descripcionAbajo="TOTAL EN VENTAS"
              datoArriba={total_cantidad_combustible}
              datoAbajo={total_valor_combustible}
              color="#4caf50"
              imgDefault={ventasCombustiblesImg}
            />
          )}
          {vectorIds.includes(INDICADOR_VENTAS_MIXTAS) && (
            <IdentificadoresDashboard
              icon={`${server.hostMultimedia}/${dominio}/indicadores/ventasMixtas.png`}
              subtitulo="VENTAS REALIZADAS"
              titulo="VENTAS MIXTAS"
              descripcionAbajo="TOTAL EN VENTAS"
              datoArriba={total_cantidad_mixtas}
              datoAbajo={total_valor_mixtas}
              color="#4caf50"
              imgDefault={ventasCombustiblesImg}
            />
          )}
          {vectorIds.includes(INDICADOR_ESTACIONES) ? (
            <IdentificadoresDashboard
              icon={`${server.hostMultimedia}/${dominio}/indicadores/estaciones.png`}
              subtitulo="ESTACIONES"
              titulo="ESTACIONES"
              descripcionAbajo="VENTAS EN ESTACIONES"
              datoArriba={total_estaciones}
              datoAbajo={!isNaN(parseFloat(parseFloat(total_valor_combustible) + parseFloat(total_valor_canastilla))) ? parseFloat(total_valor_combustible) + parseFloat(total_valor_canastilla) : 0}
              color="#666666"
              imgDefault={estacion}
            />
          ) : null}
          {vectorIds.includes(INDICADOR_VEHICULOS) ? (
            <IdentificadoresDashboard
              icon={`${server.hostMultimedia}/${dominio}/indicadores/vehiculos.png`}
              subtitulo="TANQUEOS"
              titulo="VEHÍCULOS"
              descripcionAbajo="TOTAL VEHÍCULOS"
              datoArriba={ventas_vehiculos}
              datoAbajo={total_vehiculos}
              color="#52c41a"
              tipo={true}
              imgDefault={carro}
            />
          ) : null}
          {vectorIds.includes(INDICADOR_PRODUCTOS) ? (
            <IdentificadoresDashboard
              icon={producto}
              subtitulo="PRODUCTOS"
              titulo="PRODUCTOS"
              descripcionAbajo="PRODUCTOS COMPLEMENTARIOS"
              datoArriba={total_productos_combustible}
              datoAbajo={total_productos_canastilla}
              color="#0059B2"
              tipo={true}
            />
          ) : null}
          {vectorIds.includes(INDICADOR_USUARIOS) ? (
            <IdentificadoresDashboard
              icon={`${server.hostMultimedia}/${dominio}/indicadores/usuarios.png`}
              subtitulo="EMPLEADOS "
              titulo="USUARIOS"
              descripcionAbajo="CLIENTES"
              datoArriba={this.state.total_empleados}
              datoAbajo={total_clientes}
              color="#52c41a"
              tipo={true}
              imgDefault={usuarioImg}
            />
          ) : null}
          {/*vectorIds.includes(INDICADOR_DE_COMPRAS) && (
            <IdentificadoresDashboard
              icon={compras_1Img}
              subtitulo="COMPRAS REALIZADAS "
              titulo="COMPRAS"
              descripcionAbajo="TOTAL EN COMPRAS"
              datoArriba={total_cantidad_compras}
              datoAbajo={total_valor_compras}
              color="#52c41a"
              tipo={true}
            />
          )*/}
          {vectorIds.includes(INDICADOR_DE_COMPRAS) && (
            <IdentificadoresDashboard
              icon={compras_1Img}
              subtitulo="COMPRAS REALIZADAS "
              titulo="COMPRAS"
              descripcionAbajo="TOTAL EN COMPRAS"
              datoArriba={total_cantidad_compras}
              datoAbajo={total_valor_compras}
              color="#52c41a"
            />)
          }

          {vectorIds.includes(INDICADOR_VENTAS_FIDELIZADAS) && (
            <IdentificadoresDashboard
              icon={`${server.hostMultimedia}/${dominio}/indicadores/fidelizadas.png`}
              imgDefault={ventasFidelizadasImg}
              subtitulo="TOTAL"
              titulo="VENTAS FIDELIZADAS"
              descripcionAbajo="NUMEROS "
              datoArriba={0}
              datoAbajo={0}
              color="#4caf50"
              tipo={true}
            />
          )}
          {vectorIds.includes(INDICADOR_FINANCIERAS) && (
            <IdentificadoresDashboard
              icon={financieraIMG}
              subtitulo="TOTAL"
              titulo="FINANCIERAS"
              descripcionAbajo="NUMERO DE EMPLEADOS "
              datoArriba={this.state.total_finacieras}
              datoAbajo={this.state.total_usuarios_financieras}
              color="#4caf50"
              tipo={true}
            />
          )}
          {vectorIds.includes(INDICADOR_DE_CREDITOS) && (
            <IdentificadoresDashboard
              icon={creditosIMG}
              subtitulo="CREDITOS APROBADOS"
              titulo="CREDITOS"
              descripcionAbajo="CREDITOS "
              datoArriba={this.state.total_creditos_aprobados}
              datoAbajo={this.state.total_creditos}
              color="#4caf50"
              tipo={true}
            />
          )}
          {vectorIds.includes(INDICADOR_ACUERDOS_EDS) && (
            <IdentificadoresDashboard
              icon={acuerdoEds}
              subtitulo="CONTRATOS APROBADOS"
              titulo="CONTRATOS EDS"
              descripcionAbajo="CONTRATOS "
              datoArriba={this.state.total_contratos_eds}
              datoAbajo={this.state.total_contratos_eds}
              color="#4caf50"
              tipo={true}
            />
          )}
          {vectorIds.includes(INDICADOR_DE_CONVERSIONES) && (
            <IdentificadoresDashboard
              icon={acuerdoEds}
              subtitulo="CONVERSIONES APROBADAS"
              titulo="CONVERSIONES"
              descripcionAbajo="TOTALES"
              datoArriba={this.state.total_conversiones_aprobadas}
              datoAbajo={this.state.total_conversiones}
              color="#4caf50"
              tipo={true}
            />
          )}
        </div>
        {/**ALERTAS */}
        {parametros_dominio.maneja_alertas === "S" || parametros_negocio.maneja_alertas === "S" ? <div>
          <div className="container noDatos">
            <h2 className="text-center my-2 h1 bg-primary">ALERTAS</h2>
            <div className="row mx-auto">
              <div className="col-md-3 mx-auto">
                <Alert
                  className={
                    jornadas_abiertas.length > 0 ? "hoverAlertas alerta" : "hoverAlertas"
                  }
                  style={{ marginTop: "10px" }}
                  severity="success"
                  onClick={() => this.modalAlertas("jornada_abierta")}
                >
                  <AlertTitle>JORNADAS ABIERTAS</AlertTitle>
                  <strong>CLICK AQUI!</strong> PARA VER DETALLES
                </Alert>
              </div>
              <div className="col-md-3 mx-auto">
                <Alert
                  className={
                    consecutivos.length > 0 ? "hoverAlertas alerta" : "hoverAlertas"
                  }
                  style={{ marginTop: "10px" }}
                  severity="error"
                  onClick={() => this.modalAlertas("consecutivo")}
                >
                  <AlertTitle>CONSECUTIVO</AlertTitle>
                  <strong>CLICK AQUI!</strong> PARA VER DETALLES
                </Alert>
              </div>
              <div className="col-md-3">
                <Alert
                  className={secado_virtual_datos.length > 0 ? "hoverAlertas alerta" : "hoverAlertas"}
                  style={{ marginTop: "10px" }}
                  severity="warning"
                  onClick={() => this.modalAlertas("secado_virtual")}
                >
                  <AlertTitle>SECADO VIRTUAL</AlertTitle>
                  <strong>CLICK AQUI!</strong> PARA VER DETALLES
                </Alert>
              </div>
              <div className="col-md-3 mx-auto">
                <Alert
                  className={
                    ordenes_compra.length > 0 ? "hoverAlertas alerta" : "hoverAlertas"
                  }
                  style={{ marginTop: "10px" }}
                  severity="info"
                  onClick={() => this.modalAlertas("ordenes_entrada")}
                >
                  <AlertTitle>ORDENES DE COMPRAS SIN ENTRADA</AlertTitle>
                  <strong>CLICK AQUI!</strong> PARA VER DETALLES
                </Alert>
              </div>
            </div>
          </div>
          <Dialog
            open={this.state.isShow}
            aria-labelledby="form-dialog-title"
            maxWidth={"md"}
            fullWidth={true}
            style={{ padding: "0", zIndex: 500 }}
            disableBackdropClick={false}
          >
            <DialogContent style={{ padding: "30px", zIndex: 500 }}>
              <button
                className="btn btn-primary"
                onClick={() => this.setState({ isShow: false })}
              >
                Cerrar
              </button>
              <hr />
              <div className="row">
                <h2 className="mx-auto">
                  {this.state.alerta.toLocaleUpperCase() !==
                    "JORNADA_ABIERTA" && "ALERTAS DE"}{" "}
                  {this.state.alerta.replace("_", " ").toLocaleUpperCase()}
                </h2>
              </div>
              {this.state.alerta.toLocaleUpperCase() === "CONSECUTIVO"
                ? this.detallesConsecutiva()
                : this.state.alerta.toLocaleUpperCase() === "JORNADA_ABIERTA"
                  ? this.detallesJordanaActiva()
                  : this.state.alerta.toLocaleUpperCase() === "SECADO_VIRTUAL"
                    ? this.detallesSecadoVirtual()
                    : this.detallesCompraAbierta()}
            </DialogContent>
          </Dialog>
        </div> : null}

        {/**GRAFICAS DATAGAS*/}
        {
          TIPO_DOMINIO === 'DATAGAS' &&
          (
            <div>
              <div>{graficaUltimoMesCombustible}</div>
              <div>{graficaUltimos7Dias}</div>
            </div>
          )
        }
        {/**GRAFICAS */}
        <>
          {/**Turno ultimos 7 dias */}
          {vectorIds.includes(8) ? (
            <div
              className="row"
              style={
                usuario.tipo === 5 ? { display: "none" } : { display: "none" }
              }
            >
              <div
                id="ventas-x-rangos-horas"
                className="col-md-11 mx-auto"
              ></div>
            </div>
          ) : null}

          {/**top 10 promotores vendido hoy */}
          {vectorIds.includes(PROMOTORES_VENTAS_TOP) ? (
            <div
              className="row"
              style={
                this.state.promotores_mas_ventas_hoy_show
                  ? { marginTop: "15px", display: "block" }
                  : { display: "none" }
              }
            >
              <div className="text-center noDatosExternos">
                <h2 className="text-center bg-primary">TOP PROMOTORES CON MAS VENTAS
                  {
                    tiempoTopPromotor === "DIA"
                      ? " (HOY)"
                      : tiempoTopPromotor === "SEMANA"
                        ? " (ESTA SEMANA)"
                        : tiempoTopPromotor === "MES"
                          ? " (ESTE MES)"
                          : " (ESTE AÑO)"


                  }</h2>
              </div>
              <div className="botones mx-auto text-center">
                <button
                  className={
                    checkTopPromotores === 0
                      ? "btn btn-sm btn-primary"
                      : "btn btn-sm btn-outline-dark"}
                  onClick={() => this.setearPromotoresTop(0)}
                >
                  CANASTILLA
                </button>
                <button
                  className={
                    checkTopPromotores === 1
                      ? "btn btn-sm btn-primary"
                      : "btn btn-sm btn-outline-dark"}
                  onClick={() => this.setearPromotoresTop(1)}
                >
                  COMBUSTIBLE
                </button>
                <button
                  className={
                    checkTopPromotores === 2
                      ? "btn btn-sm btn-primary"
                      : "btn btn-sm btn-outline-dark"}
                  onClick={() => this.setearPromotoresTop(2)}
                >
                  TOTAL
                </button>
              </div>
              <div className="botones mx-auto text-center">
                <button
                  className={
                    tiempoTopPromotor === "DIA"
                      ? "btn btn-sm btn-primary"
                      : "btn btn-sm btn-outline-dark"}
                  onClick={() => this.setearTiempoGraficas("tiempoTopPromotor", "DIA")}
                >
                  HOY
                </button>
                <button
                  className={
                    tiempoTopPromotor === "SEMANA"
                      ? "btn btn-sm btn-primary"
                      : "btn btn-sm btn-outline-dark"}
                  onClick={() => this.setearTiempoGraficas("tiempoTopPromotor", "SEMANA")}
                >
                  ESTA SEMANA
                </button>
                <button
                  className={
                    tiempoTopPromotor === "MES"
                      ? "btn btn-sm btn-primary"
                      : "btn btn-sm btn-outline-dark"}
                  onClick={() => this.setearTiempoGraficas("tiempoTopPromotor", "MES")}
                >
                  ESTE MES
                </button>
                <button
                  className={
                    tiempoTopPromotor === "ANO"
                      ? "btn btn-sm btn-primary"
                      : "btn btn-sm btn-outline-dark"}
                  onClick={() => this.setearTiempoGraficas("tiempoTopPromotor", "ANO")}
                >
                  ESTE AÑO
                </button>
              </div>
              <div
                id="ventas-x-promotor-top"
                className="col-md-11 mx-auto mb-3"
              ></div>
            </div>
          ) : null}

          {/**Ventas promotor hoy por estacion */}
          {vectorIds.includes(26) ? (
            <div className="row" style={{ marginTop: "15px", display: "none" }}>
              <div id="ventas-x-promotor" className="col-md-11 mx-auto"></div>
            </div>
          ) : null}
          {/**Ventas del mes PRODUCTOS */}
          {vectorIds.includes(10) ? <div>{graficaMesActual}</div> : null}

          {/**Top 10 estaciones que mas vendieron hoy */}
          {vectorIds.includes(11) ? (
            <div
              className="row"
              style={
                this.state.estaciones_mas_ventas_hoy_show
                  ? { marginTop: "15px", display: "block" }
                  : { display: "none" }
              }
            >
              <div className="botones mx-auto text-center">
                <button
                  className="btn btn-sm"
                  onClick={() => this.setTopEstaciones(0)}
                >
                  COMBUSTIBLE
                </button>
                <button
                  className="btn btn-sm"
                  onClick={() => this.setTopEstaciones(1)}
                >
                  CANASTILLA
                </button>
                <button
                  className="btn btn-sm"
                  onClick={() => this.setTopEstaciones(2)}
                >
                  TOTAL
                </button>
              </div>
              <div
                id="ventas-x-estacion-top"
                className="col-md-11 mx-auto"
              ></div>
            </div>
          ) : null}
          {!this.state.estaciones_mas_ventas_hoy_show && vectorIds.includes(11) && (
            <div className="container my-4 noDatosExternos">
              <h2 className="text-center bg-primary">
                TOP 10 ESTACIONES QUE MAS VENDIERON HOY
              </h2>{" "}
              <span>NO HAY DATOS</span>{" "}
            </div>
          )}
          {/**Top 10 productos mas vendidos hoy*/}
          {vectorIds.includes(12) ? (
            <div
              className="row"
              style={
                this.state.productos_mas_vendidos_hoy_show
                  ? { marginTop: "15px", display: "block" }
                  : { display: "none" }
              }
            >
              <div
                id="ventas-x-producto-top"
                className="col-md-11 mx-auto"
              ></div>
            </div>
          ) : null}
          {!this.state.productos_mas_vendidos_hoy_show && vectorIds.includes(12) && (
            <div className="text-center noDatos">
              {" "}
              <h2 className="text-center bg-primary">
                TOP 10 PRODUCTOS MAS VENDIDOS HOY
              </h2>{" "}
              <span>NO HAY DATOS</span>{" "}
            </div>
          )}
          {/** Venta de producto por meses COMBUSTIBLE */}
          {vectorIds.includes(13) ? (
            <div>{graficaUltimoMesCombustible}</div>
          ) : null}
          {/** Venta de COMBUSTIBLE ULTIMOS 7 DIAS*/}
          {vectorIds.includes(CONVERSIONES_ESTA_SEMANA) ? <div>{graficaUltimos7Dias}</div> : null}
          {vectorIds.includes(VENTA_COMBUSTIBLE_MES_ACTUAL) ? <div>{graficaUltimos7Dias}</div> : null}
          {vectorIds.includes(CREDITOS_ESTA_SEMANA) ? <div>{credito_7_dias}</div> : null}
          {/** Venta por isla*/}
          {vectorIds.includes(15) ? (
            <div>
              <GraficoIsla />
            </div>
          ) : null}
          {vectorIds.includes(COMBINADO) ? (
            <>
              <div className="row">
                <div className="container noDatosExternos mt-4">
                  <h2 className="text-center bg-primary">VENTAS POR ISLAS Y PRODUCTOS</h2>
                </div>
              </div>
              <div className="row">
                <div className="botones mx-auto text-center container">
                  <button
                    className={
                      tiempoCombinado === "DIA"
                        ? "btn btn-sm btn-primary"
                        : "btn btn-sm btn-outline-dark"}
                    onClick={() => this.setearTiempoGraficas("tiempoCombinado", "DIA")}
                  >
                    HOY
                  </button>
                  <button
                    className={
                      tiempoCombinado === "SEMANA"
                        ? "btn btn-sm btn-primary"
                        : "btn btn-sm btn-outline-dark"}
                    onClick={() => this.setearTiempoGraficas("tiempoCombinado", "SEMANA")}
                  >
                    ESTA SEMANA
                  </button>
                  <button
                    className={
                      tiempoCombinado === "MES"
                        ? "btn btn-sm btn-primary"
                        : "btn btn-sm btn-outline-dark"}
                    onClick={() => this.setearTiempoGraficas("tiempoCombinado", "MES")}
                  >
                    ESTE MES
                  </button>
                  <button
                    className={
                      tiempoCombinado === "ANO"
                        ? "btn btn-sm btn-primary"
                        : "btn btn-sm btn-outline-dark"}
                    onClick={() => this.setearTiempoGraficas("tiempoCombinado", "ANO")}
                  >
                    ESTE AÑO
                  </button>
                </div>
              </div>
              <div id="mixto" className="col-md-11 mx-auto"></div>
            </>
          ) : null}
          {vectorIds.includes(GRAFICA_TOP_DIAS_MAS_VENTAS) ? (
            <>
              <div className="row">
                <div className="container noDatosExternos mt-4">
                  <h2 className="text-center bg-primary">DIAS CON MAYORES VENTAS</h2>
                </div>
              </ div>
              <div className="row">
                <div className="botones mx-auto text-center container">
                  <button
                    className={
                      tiempoTopSemanal === "SEMANA"
                        ? "btn btn-sm btn-primary"
                        : "btn btn-sm btn-outline-dark"}
                    onClick={() => this.setearTiempoGraficas("tiempoTopSemanal", "SEMANA")}
                  >
                    ULTIMA SEMANA
                  </button>
                  <button
                    className={
                      tiempoTopSemanal === "MES"
                        ? "btn btn-sm btn-primary"
                        : "btn btn-sm btn-outline-dark"}
                    onClick={() => this.setearTiempoGraficas("tiempoTopSemanal", "MES")}
                  >
                    ESTE MES
                  </button>
                  <button
                    className={
                      tiempoTopSemanal === "ANO"
                        ? "btn btn-sm btn-primary"
                        : "btn btn-sm btn-outline-dark"}
                    onClick={() => this.setearTiempoGraficas("tiempoTopSemanal", "ANO")}
                  >
                    ESTE AÑO
                  </button>
                </div>
                <div id="top-ventas" className="col-md-11 mx-auto"></div>
              </div>
            </>
          ) : null}
          {/** Grafico torta*/}
          {vectorIds.includes(18) ? <div>{graficoTorta}</div> : null}
        </>
      </div>
    );
  }
}

export default Dashboard;
