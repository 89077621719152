import Loader from 'components/Loader'
import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
//rutas
const Estaciones = lazy(async () => import('./Estaciones'))
const Estructura = lazy(async () => import('./Estructura/index'))
const GestionTalleres = lazy(async () => import('./GestionTalleres/index'))
const Islas = lazy(async () => import('./Islas/index'))
const Monitoreo = lazy(async () => import('./Monitoreo/index'))
const Negocios = lazy(async () => import('./Negocios/index'))
const Vehiculos = lazy(async () => import('./Vehiculos'))
const configuracionEds = lazy(async () => import('./Eds/index'))
//typescript
const Soportes = lazy(async () => import('./Soportes'))
const Flotas = lazy(async () => import('./Flotas'))

const Dashboard = ({ match }) => {
  return (
    <div className="app-wrapper">
      <Switch>
        <Suspense fallback={<Loader />}>
          <Route path={`${match.url}/eds`} component={configuracionEds} />
          <Route path={`${match.url}/estructura`} component={Estructura} />
          <Route path={`${match.url}/flotas`} component={Flotas} />
          <Route path={`${match.url}/gestion_talleres`} component={GestionTalleres} />
          <Route path={`${match.url}/islas`} component={Islas} />
          <Route path={`${match.url}/mis-eds`} component={Estaciones} />
          <Route path={`${match.url}/monitoreo`} component={Monitoreo} />
          <Route path={`${match.url}/negocios`} component={Negocios} />
          <Route path={`${match.url}/soporte`} component={Soportes} />
          <Route path={`${match.url}/vehiculos`} component={Vehiculos} />
        </Suspense>
      </Switch>
    </div>
  )
}

export default Dashboard
