
import React, { useState, useEffect } from 'react'
import { Accordion, AccordionSummary, Typography } from '@material-ui/core';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Selects from "react-select";
import Storage from "util/store/Storage";
import { obtenerValoresArray } from 'util/funciones';
import { getSelectEDS } from 'app/vistas/Reportes/servicios/serviciosSelect';
const FilterEntiti = ({ onClick }: any): JSX.Element => {
    const [empresaSelect, setEmpresaSelect] = useState([] as any)
    const [empresaAll, setEmpresaAll] = useState([] as any)
    const { empresasHijos } = Storage.getState()

    useEffect(() => {
        obtenerData()
    }, [])

    const obtenerData = async (): Promise<void> => {
        setEmpresaAll(await getSelectEDS(empresasHijos))
    }

    const validateEntity = () => {
        if (empresaSelect && empresaSelect.length > 0) {
            onClick(obtenerValoresArray(empresaSelect))
        } else {
            onClick(empresasHijos)
        }
    }

    return (
        < Accordion >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>FILTROS</Typography>
            </AccordionSummary>
            <div className="row" style={{ padding: "10px" }}>
                <div className="col-md-8 col-12">
                    <label htmlFor="tipo">EMPRESA</label>
                    <Selects
                        isMulti
                        placeholder="SELECCIONE LA EMPRESA"
                        value={empresaSelect}
                        onChange={(value) => setEmpresaSelect(value)}
                        options={empresaAll}
                    />
                </div>
                <div className="col-md-4 col-sm-4 mt-2">
                    <button
                        className="btn btn-primary btn-block mt-4"
                        onClick={validateEntity}
                    >
                        BUSCAR
                    </button>
                </div>
            </div>
        </Accordion >
    )
}
export default FilterEntiti


