import Axios from 'axios';
import server from 'util/Server';
import { sessionCaducada } from 'util/funciones'
//import Storage from 'util/store/Storage';
export const getSelectEDS = async (empresas) => {
  return new Promise(function (resolve, reject) {
    Axios.get(
      server.host + server.puertoCentury + "/api/empresa/select/empresa/5/" + empresas,
      { headers: { Authorization: server.obtenerToken() } }
    )
      .then((res) => {
        if (res.status === server.responseOK) {
          const datos = res.data.data;
          resolve(datos)
        } else if (res.status === server.responseSesionCaducada) {
          sessionCaducada()
          resolve([])
        }
      })
      .catch((err) => {
        reject(err, undefined)
        console.error("Error ->" + err);
      });
  });
};
//obtener tipos productos
export const getSelectTiposProductosCombustibles = async (empresas) => {
  return new Promise(function (resolve, reject) {
    Axios.get(
      server.host + server.puertoCentury + "/api/entidad/select/getparent/21/" + empresas,
      { headers: { Authorization: server.obtenerToken() } }
    )
      .then((res) => {
        if (res.status === server.responseOK) {
          const datos = res.data.data;
          resolve(datos)
        } else if (res.status === server.responseSesionCaducada) {
          sessionCaducada()
          resolve([])
        }
      })
      .catch((err) => {
        reject(err, undefined)
        console.error("Error ->" + err);
      });
  });
};

//obtener tipos productos
export const getSelectIdentificadoresOrigenesByEntidadID = async (entidad_id) => {
  return new Promise(function (resolve, reject) {
    Axios.get(
      server.host + server.puertoCentury + "/api/medios-identificacion/select/origen/" + entidad_id,
      { headers: { Authorization: server.obtenerToken() } }
    )
      .then((res) => {
        if (res.status === server.responseOK) {
          const datos = res.data.data;
          resolve(datos)
        } else if (res.status === server.responseSesionCaducada) {
          sessionCaducada()
          resolve([])
        }
      })
      .catch((err) => {
        reject(err, undefined)
        console.error("Error ->" + err);
      });
  });
};

//obtener promotores
export const getSelectPromotores = async (empresas) => {
  return new Promise(function (resolve, reject) {
    Axios.get(
      server.host + server.puertoCentury + "/api/persona/select/empresas/" + empresas,
      { headers: { Authorization: server.obtenerToken() } }
    )
      .then((res) => {
        if (res.status === server.responseOK) {
          const datos = res.data;
          resolve(datos)
        } else if (res.status === server.responseSesionCaducada) {
          sessionCaducada()
          resolve([])
        }
      })
      .catch((err) => {
        reject(err, undefined)
        console.error("Error ->" + err);
      });
  });
};

//obtener surtidores
export const getSelectSurtidores = async (empresas) => {
  return new Promise(function (resolve, reject) {
    Axios.get(
      server.host + server.puertoCentury + "/api/surtidor/select/" + empresas,
      { headers: { Authorization: server.obtenerToken() } }
    )
      .then((res) => {
        if (res.status === server.responseOK) {
          const datos = res.data.data;
          resolve(datos)
        } else if (res.status === server.responseSesionCaducada) {
          sessionCaducada()
          resolve([])
        }
      })
      .catch((err) => {
        reject(err, undefined)
        console.error("Error ->" + err);
      });
  });
};
//obtener equipos por empresa_id
export const getSelectEquiposPorEmpresaID = async (empresas) => {
  return new Promise(function (resolve, reject) {
    Axios.get(
      server.host + server.puertoCentury + "/api/equipo/select/empresa/" + empresas,
      { headers: { Authorization: server.obtenerToken() } }
    )
      .then((res) => {
        if (res.status === server.responseOK) {
          const datos = res.data.data;
          resolve(datos)
        } else if (res.status === server.responseSesionCaducada) {
          sessionCaducada()
          resolve([])
        }
      })
      .catch((err) => {
        reject(err, undefined)
        console.error("Error ->" + err);
      });
  });
};

//obtener mangueras por surtidor id
export const getManguerasSurtidor = async (surtidor_id) => {
  return new Promise(function (resolve, reject) {
    Axios.get(
      server.host + server.puertoCentury + "/api/surtidor/select/detalles/" + surtidor_id,
      { headers: { Authorization: server.obtenerToken() } }
    )
      .then((res) => {
        if (res.status === server.responseOK) {
          const datos = res.data.data;
          resolve(datos)
        } else if (res.status === server.responseSesionCaducada) {
          sessionCaducada()
          resolve([])
        }
      })
      .catch((err) => {
        reject(err, undefined)
        console.error("Error ->" + err);
      });
  });
};
//clientes por dominio
export const getClientesByDomino = async (dominio_id) => {
  return new Promise(function (resolve, reject) {
    Axios.get(
      server.host + server.puertoCentury + "/api/cliente/dominio2/" + dominio_id,
      { headers: { Authorization: server.obtenerToken() } }
    )
      .then((res) => {
        if (res.status === server.responseOK) {
          const datos = res.data.data.map((cliente => ({
            value: cliente.id,
            label: cliente.identificacion + ' - ' + cliente.nombre,
            credito: cliente.atributos.value === 'S',
            identificacion: cliente.identificacion,
            nombre: cliente.nombre
          })));
          resolve(datos)
        } else if (res.status === server.responseSesionCaducada) {
          sessionCaducada()
          resolve([])
        }
      })
      .catch((err) => {
        reject(err, undefined)
        console.error("Error ->" + err);
      });
  });
};
//vehiculos por cliente
export const getVehiculosByCliente = async (cliente_id) => {
  return new Promise(function (resolve, reject) {
    Axios.get(
      server.host + server.puertoCentury + "/api/vehiculo/select2/" + cliente_id,
      { headers: { Authorization: server.obtenerToken() } }
    )
      .then((res) => {
        if (res.status === server.responseOK) {
          const datos = res.data.data;
          resolve(datos)
        } else if (res.status === server.responseSesionCaducada) {
          sessionCaducada()
          resolve([])
        }
      })
      .catch((err) => {
        reject(err, undefined)
        console.error("Error ->" + err);
      });
  });
};
//flotas por cliente
export const getFlotasByCliente = async (cliente_id) => {
  return new Promise(function (resolve, reject) {
    Axios.get(server.host + server.puertoCentury + "/api/flota/select/" + cliente_id, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseOK) {
          const datos = res.data.data;
          resolve(datos)
        } else if (res.status === server.responseSesionCaducada || res.status === server.responseErrorServidor) {
          sessionCaducada()
          resolve([])
        }
      })
      .catch((err) => {
        reject(err, undefined)
        console.error("Error ->" + err);
      })
  });
};
//vehiculos por cliente
export const getContratosPorVehiculo = async (vehiculo_id) => {
  return new Promise(function (resolve, reject) {
    Axios.get(
      server.host + server.puertoCentury + "/api/contrato/contratos/select/vehiculos/" + vehiculo_id,
      { headers: { Authorization: server.obtenerToken() } }
    )
      .then((res) => {
        if (res.status === server.responseOK) {
          const datos = res.data;
          resolve(datos)
        } else if (res.status === server.responseSesionCaducada) {
          sessionCaducada()
          resolve([])
        }
      })
      .catch((err) => {
        reject(err, undefined)
        console.error("Error ->" + err);
      });
  });
};
//medio de pago por empresas_id
export const getMediosPagoEmpresaID = async (empresas_id) => {
  return new Promise(function (resolve, reject) {
    Axios.get(
      server.host + server.puertoCentury + "/api/medio-pago/select/" + empresas_id,
      { headers: { Authorization: server.obtenerToken() } }
    )
      .then((res) => {
        if (res.status === server.responseOK) {
          const datos = res.data;
          resolve(datos)
        } else if (res.status === server.responseSesionCaducada) {
          sessionCaducada()
          resolve([])
        }
      })
      .catch((err) => {
        reject(err, undefined)
        console.error("Error ->" + err);
      });
  });
};
//jornadas por empresa_id y promotor_id
export const getJornadasByEmpresaID = async (body) => {
  return new Promise(function (resolve, reject) {
    Axios.post(
      server.host + server.puertoCentury + "/api/horario/jornada/select/",
      body,
      { headers: { Authorization: server.obtenerToken() } }
    )
      .then((res) => {
        if (res.status === server.responseOK) {
          const datos = res.data.data;
          resolve(datos)
        } else if (res.status === server.responseSesionCaducada) {
          sessionCaducada()
          resolve([])
        }
      })
      .catch((err) => {
        reject(err, undefined)
        console.error("Error ->" + err);
      });
  });
};
// get financiersa por dominio
export const getSelectFinancierasDominio = async (dominio) => {
  return new Promise(function (resolve, reject) {
    Axios.get(
      server.host + server.puertoCentury + "/api/financiera/select/" + dominio,
      { headers: { Authorization: server.obtenerToken() } }
    )
      .then((res) => {
        if (res.status === server.responseOK) {
          const datos = res.data.data;
          resolve(datos)
        } else if (res.status === server.responseSesionCaducada) {
          sessionCaducada()
          resolve([])
        }
      })
      .catch((err) => {
        reject([])
        console.error(err);
      });
  });

};

/**
 * Description: Obtener opciones de selects Talleres
 * @param {number} dominio Storage.getState().dominio
 * @returns array con los objetos que pueden ser seleccionados
 */
export const getSelectTallerDominio = async (dominio) => {
  return new Promise(function (resolve, reject) {
    Axios.get(
      server.host + server.puertoCentury + "/api/taller/select/" + dominio,
      { headers: { Authorization: server.obtenerToken() } }
    )
      .then((res) => {
        if (res.status === server.responseOK) {
          const datos = res.data.data;
          resolve(datos)
        } else if (res.status === server.responseSesionCaducada) {
          sessionCaducada()
          resolve([])
        }
      })
      .catch((err) => {
        reject([])
        console.error(err);
      });
  });
};

/**
 * Description: Obtener opciones de selects Verificadoras
 * @param {number} dominio Storage.getState().dominio
 * @returns array con los objetos que pueden ser seleccionados
 */
export const getSelectVerificadoraDominio = async (dominio) => {
  return new Promise(function (resolve, reject) {
    Axios.get(
      server.host + server.puertoCentury + "/api/verificadora/select/" + dominio,
      { headers: { Authorization: server.obtenerToken() } }
    )
      .then((res) => {
        if (res.status === server.responseOK) {
          const datos = res.data.data;
          resolve(datos)
        } else if (res.status === server.responseSesionCaducada) {
          sessionCaducada()
          resolve([])
        }
      })
      .catch((err) => {
        reject([])
        console.error(err);
      });
  });
};
