import React, { useEffect, useState } from 'react'
//panel del filtro
import HeaderTitle from './Components/HeaderTitle'
import Highcharts from 'highcharts'
import HCSoldGauge from 'highcharts/modules/solid-gauge';
import { setearMiles } from "util/funciones"
import { determinateDate, determinateOperation, getSeriesVentasCombustible } from '../ChartHelpers/Chart.helper';
import { formatearFecha, formatosFecha } from 'util/formatDate';
import moment from 'moment';
import { getChartsData } from '../ChartHelpers/Charts.services';
import { IDatasetChart } from '../ChartHelpers/Charts.interface';
import { FiltrosOperation, FiltrosFecha } from './Components';
window.Highcharts = Highcharts;
//ChartModuleMore(Highcharts);
HCSoldGauge(Highcharts);
const ChartVentaMeses = ({ updateData, identificadorEstacion }: any): JSX.Element => {
    const [selectionDate, setSelectionDate] = useState(0)
    const [selectionOperation, setSelectionOperation] = useState(3)

    useEffect(() => {
        getData()
    }, [updateData, selectionDate, selectionOperation, identificadorEstacion])

    const getData = async (): Promise<void> => {
        const { fechaInicial, fechaFinal } = determinateDate(selectionDate)
        const { operacion, estadoMovimiento } = determinateOperation(selectionOperation)
        const body = {
            fecha_inicial: fechaInicial,
            fecha_final: fechaFinal,
            identificadorEstacion: identificadorEstacion,
            tipoMovimiento: operacion,
            tipoEstadoMovimiento: estadoMovimiento
        };
        const data = await getChartsData('VENTAS_MESES_MESES_LITE', body)
        construirDatos(data)
    }

    const construirDatos = (datos: any[]) => {
        console.log(datos)
        const data: IDatasetChart = {
            categories: [],
            series: []
        }
        try {
            if (datos != null) {
                datos.forEach((mes) => {
                    data.categories.push(`${mes.mes} (${mes.cantidad ?? ''} Ventas)`)
                    data.series.push(parseFloat(mes.sum))
                })
            }
        } catch (error) { }
        construirGrafico(data.categories, data.series)
    }



    const construirGrafico = (categories: string[], series: any[]): void => {
        console.log(categories,series)
        Highcharts.chart("ventas-meses", {
            chart: {
                type: "column",
            },
            title: {
                text: "",
            },
            xAxis: {
                categories: categories,
                crosshair: true,
            },
            yAxis: {
                min: 0,
                title: {
                    text: "VENTAS POR MESES",
                },
            }, tooltip: {
                formatter(this: Highcharts.TooltipFormatterContextObject) {
                    return '<b>' + this.point.category + '</b>:  $' + setearMiles(this.point.y);
                }
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                },
            },
            series: series,
        });
    }


    return (
        <>
            <HeaderTitle title={'VENTA POR MESES DEL AÑO'} />
            <FiltrosFecha onChange={(selection: number) => setSelectionDate(selection)} />
            <FiltrosOperation onChange={(selection: number) => setSelectionOperation(selection)} prevActive={selectionOperation} />
            <div
                id="ventas-meses"
                className="col-md-11 mx-auto"
            /*style={
                isShow
                    ? { marginTop: "15px", display: "block" }
                    : { display: "none" }
            }*/
            ></div>
        </>
    )
}
export default ChartVentaMeses
