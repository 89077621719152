import React, { useEffect, useState } from 'react'
import { formatearFecha, formatosFecha } from 'util/formatDate';
import Storage from 'util/store/Storage'
import { getIndicadoresLazoLite } from '../../servicios';
import {
    INDICADOR_FINANCIERAS,
    INDICADOR_DE_CREDITOS,
    INDICADOR_USUARIOS,
    INDICADOR_DE_COMPRAS,
    CONVERSIONES_ESTA_SEMANA,
    INDICADOR_ACUERDOS_EDS,
    INDICADOR_DE_CONVERSIONES,
    CREDITOS_ESTA_SEMANA,
    INDICADOR_VENTAS,
    INDICADOR_ESTACIONES,
    INDICADOR_PRODUCTOS,
    INDICADOR_VEHICULOS,
    INDICADOR_VENTAS_CANASTILLA,
    INDICADOR_VENTAS_FIDELIZADAS,
    COMBINADO,
    PROMOTORES_VENTAS_TOP,
    GRAFICA_TOP_DIAS_MAS_VENTAS,
    VENTA_COMBUSTIBLE_MES_ACTUAL,
    INDICADOR_VENTAS_MIXTAS
} from "../../../modulos_constant";
import IdentificadoresDashboard from "../../componentes/indicadores/Indicadores";
import server from "util/Server";
import ventasCanastillaImg from "../../../../../assets/images/widget/mmoney.png";
import producto from "../../../../../assets/images/widget/mpro.png";
import carro from "../../../../../assets/images/widget/car.png";
import usuarioImg from "../../../../../assets/images/widget/muser.png";
import ventasFidelizadasImg from "../../../../../assets/images/widget/ventasFidelizadas.png";
import ventasCombustiblesImg from "../../../../../assets/images/widget/ventasCombustibles.png";
import compras_1Img from "../../../../../assets/images/widget/compras_1.png";
import estacion from "../../../../../assets/images/widget/mstation.png";
import { IIndicadoresLazo } from '../../ChartHelpers/Charts.interface';
import HeaderTitle from './HeaderTitle';
const BarraIndicadores = ({ updateData, identificadorEstacion }: any): JSX.Element => {
    const [indicadores, setIndicadores] = useState({} as IIndicadoresLazo)
    const [vectorIds, setVectorIds] = useState<any[]>([])
    const { usuario, dominio } = Storage.getState();
    useEffect(() => {
        indicadoresLazo()
    }, [updateData, identificadorEstacion])
    useEffect(() => {
        obtenerModulos()
    }, [])

    const obtenerModulos = () => {

        const modulos = usuario.modulos;
        const ids: any[] = [];

        if (modulos != null) {
            modulos.forEach((mod: any) => {
                ids.push(mod.id);
            });
            setVectorIds(ids)
        }
    };

    const indicadoresLazo = async () => {
        var { usuario } = Storage.getState();
        const sendFetch = {
            fecha_inicial: formatearFecha(new Date(), formatosFecha.fechaServidor + ' 00:00:00'),
            fecha_final: formatearFecha(new Date(), formatosFecha.fechaServidor + ' 23:59:59'),
            identificadorEstacion: identificadorEstacion,
            identificadorNegocio: usuario.empresas_id,
        };
        const {
            total_cantidad_canastilla,
            total_cantidad_combustible,
            total_cantidad_compras,
            total_cantidad_fidelizadas,
            total_cantidad_mixtas,
            total_clientes,
            total_empleados,
            total_estaciones,
            total_productos_canastilla,
            total_productos_combustible,
            total_valor_canastilla,
            total_valor_combustible,
            total_valor_compras,
            total_valor_fidelizadas,
            total_valor_mixtas,
            total_cantidad_kioscos,
            total_valor_kioscos,
            total_vehiculos,
            ventas_vehiculos
        } = await getIndicadoresLazoLite(sendFetch)
        setIndicadores({
            total_cantidad_canastilla,
            total_cantidad_combustible,
            total_cantidad_compras,
            total_cantidad_fidelizadas,
            total_cantidad_mixtas,
            total_clientes,
            total_empleados,
            total_estaciones,
            total_productos_canastilla,
            total_productos_combustible,
            total_valor_canastilla,
            total_valor_combustible,
            total_valor_compras,
            total_valor_fidelizadas,
            total_valor_mixtas,
            total_cantidad_kioscos,
            total_valor_kioscos,
            total_vehiculos,
            ventas_vehiculos
        });
    }


    return (
        <>
            <HeaderTitle title='INDICADORES' />
            <div className="row">

                {vectorIds.includes(INDICADOR_VENTAS_CANASTILLA) ? (
                    <IdentificadoresDashboard
                        icon={`${server.hostMultimedia}/${dominio}/indicadores/ventasCanastillas.png`}
                        imgDefault={ventasCanastillaImg}
                        subtitulo="VENTAS REALIZADAS"
                        titulo="VENTAS CANASTILLA"
                        descripcionAbajo="Total de ventas"
                        datoArriba={indicadores.total_cantidad_canastilla}
                        datoAbajo={indicadores.total_valor_canastilla ?? 0}
                        color="#ff7f00"
                    />
                ) : null}

                {vectorIds.includes(INDICADOR_VENTAS) && (
                    <IdentificadoresDashboard
                        icon={`${server.hostMultimedia}/${dominio}/indicadores/ventasCombustibles.png`}
                        subtitulo="VENTAS REALIZADAS"
                        titulo="VENTAS COMBUSTIBLE"
                        descripcionAbajo="TOTAL EN VENTAS"
                        datoArriba={indicadores.total_cantidad_combustible}
                        datoAbajo={indicadores.total_valor_combustible ?? 0}
                        color="#4caf50"
                        imgDefault={ventasCombustiblesImg}
                    />
                )}
                {vectorIds.includes(INDICADOR_VENTAS_CANASTILLA) && (
                    <IdentificadoresDashboard
                        icon={`${server.hostMultimedia}/${dominio}/indicadores/ventasKiosco.png`}
                        subtitulo="VENTAS REALIZADAS"
                        titulo="VENTAS KIOSCO"
                        descripcionAbajo="TOTAL EN VENTAS"
                        datoArriba={indicadores.total_cantidad_kioscos}
                        datoAbajo={indicadores.total_valor_kioscos ?? 0}
                        color="#4caf50"
                        imgDefault={ventasCombustiblesImg}
                    />
                )}
                {vectorIds.includes(INDICADOR_VENTAS_MIXTAS) && (
                    <IdentificadoresDashboard
                        icon={`${server.hostMultimedia}/${dominio}/indicadores/ventasMixtas.png`}
                        subtitulo="VENTAS REALIZADAS"
                        titulo="VENTAS MIXTAS"
                        descripcionAbajo="TOTAL EN VENTAS"
                        datoArriba={indicadores.total_cantidad_mixtas}
                        datoAbajo={indicadores.total_valor_mixtas ?? 0}
                        color="#4caf50"
                        imgDefault={ventasCombustiblesImg}
                    />
                )}
                {/*vectorIds.includes(INDICADOR_ESTACIONES) ? (
                    <IdentificadoresDashboard
                        icon={`${server.hostMultimedia}/${dominio}/indicadores/estaciones.png`}
                        subtitulo="ESTACIONES"
                        titulo="ESTACIONES"
                        descripcionAbajo="VENTAS EN ESTACIONES"
                        datoArriba={indicadores.total_estaciones}
                        datoAbajo={!isNaN(parseFloat(indicadores.total_valor_combustible + '') + parseFloat(indicadores.total_valor_canastilla + '')) ? parseFloat(indicadores.total_valor_combustible + '') + parseFloat(indicadores.total_valor_canastilla + '') : 0}
                        color="#666666"
                        imgDefault={estacion}
                    />
                ) : null*/}
                {vectorIds.includes(INDICADOR_VEHICULOS) ? (
                    <IdentificadoresDashboard
                        icon={`${server.hostMultimedia}/${dominio}/indicadores/vehiculos.png`}
                        subtitulo="TANQUEOS"
                        titulo="VEHÍCULOS"
                        descripcionAbajo="TOTAL VEHÍCULOS"
                        datoArriba={indicadores.ventas_vehiculos}
                        datoAbajo={indicadores.total_vehiculos ?? 0}
                        color="#52c41a"
                        tipo={true}
                        imgDefault={carro}
                    />
                ) : null}
                {vectorIds.includes(INDICADOR_PRODUCTOS) ? (
                    <IdentificadoresDashboard
                        icon={`${server.hostMultimedia}/${dominio}/indicadores/productos.png`}
                        imgDefault={producto}
                        subtitulo="PRODUCTOS COMBUSTIBLE"
                        titulo="PRODUCTOS"
                        descripcionAbajo="PRODUCTOS COMPLEMENTARIOS"
                        datoArriba={indicadores.total_productos_combustible}
                        datoAbajo={indicadores.total_productos_canastilla ?? 0}
                        color="#0059B2"
                        tipo={true}
                    />
                ) : null}
                {vectorIds.includes(INDICADOR_USUARIOS) ? (
                    <IdentificadoresDashboard
                        icon={`${server.hostMultimedia}/${dominio}/indicadores/usuarios.png`}
                        subtitulo="EMPLEADOS "
                        titulo="USUARIOS"
                        descripcionAbajo="CLIENTES"
                        datoArriba={indicadores.total_empleados}
                        datoAbajo={indicadores.total_clientes ?? 0}
                        color="#52c41a"
                        tipo={true}
                        imgDefault={usuarioImg}
                    />
                ) : null}
                {/*vectorIds.includes(INDICADOR_DE_COMPRAS) && (
            <IdentificadoresDashboard
              icon={compras_1Img}
              subtitulo="COMPRAS REALIZADAS "
              titulo="COMPRAS"
              descripcionAbajo="TOTAL EN COMPRAS"
              datoArriba={total_cantidad_compras}
              datoAbajo={total_valor_compras}
              color="#52c41a"
              tipo={true}
            />
          )*/}
                {vectorIds.includes(INDICADOR_DE_COMPRAS) && (
                    <IdentificadoresDashboard
                        icon={compras_1Img}
                        subtitulo="COMPRAS REALIZADAS "
                        titulo="COMPRAS"
                        descripcionAbajo="TOTAL EN COMPRAS"
                        datoArriba={indicadores.total_cantidad_compras}
                        datoAbajo={indicadores.total_valor_compras ?? 0}
                        color="#52c41a"
                    />)
                }

                {vectorIds.includes(INDICADOR_VENTAS_FIDELIZADAS) && (
                    <IdentificadoresDashboard
                        icon={`${server.hostMultimedia}/${dominio}/indicadores/fidelizadas.png`}
                        imgDefault={ventasFidelizadasImg}
                        subtitulo="TOTAL"
                        titulo="VENTAS FIDELIZADAS"
                        descripcionAbajo="NUMEROS "
                        datoArriba={indicadores.total_cantidad_fidelizadas}
                        datoAbajo={indicadores.total_valor_fidelizadas ?? 0}
                        color="#4caf50"
                    />
                )}
            </div>
        </>
    )
}
export default BarraIndicadores
