import React, { useEffect, useState } from 'react'
import ContainerHeader from 'components/ContainerHeader/index'
import Storage from 'util/store/Storage'
import "./Alerta.css";
//Graficas
import {
    ChartVentaCombustiblePorMeses,
    ChartVentaCombustible7dias,
    ChartDiasMasVentas,
    ChartTopPromotores,
    ChartTopProductos,
    ChartTopEstaciones,
    ChartTopVolumenes,
    ChartSaldosTanques,
    ChartIslasAndProductos,
    ChartVentaMeses
} from './Charts';
import { formatearFecha, formatosFecha } from 'util/formatDate';
import { BarraIndicadores, FilterEntity, PanelAlertas } from './Charts/Components'
//iconos
import { PlayArrow, Pause } from '@material-ui/icons'
// Hidhcharts
import Highcharts from 'highcharts'
import ChartModuleMore from 'highcharts/highcharts-more.js';
import HCSoldGauge from 'highcharts/modules/solid-gauge';
import Exporting from 'highcharts/modules/exporting';
import ExportingData from 'highcharts/modules/export-data';
ChartModuleMore(Highcharts);
HCSoldGauge(Highcharts);
Exporting(Highcharts);
ExportingData(Highcharts);
const Dashboard = (props: any, { match }: any): JSX.Element => {
    const { negocio, empresasHijos } = Storage.getState()
    const [update, setUpdate] = useState(0)
    const [lastUpdate, setLastupdate] = useState(new Date())
    const [status, setStatus] = useState(true)
    const [empresaSelect, setEmpresaSelect] = useState(empresasHijos as any)

    let intervalId: any = 0

    useEffect(() => {
        if (status) {
            startInterval()
        } else {
            stopInterval()
        }
        return () => { stopInterval() }
    }, [status, update])

    const startInterval = () => {
        intervalId = setInterval(() => { setUpdate(update + 1); setLastupdate(new Date()) }, 20000)
    }
    const stopInterval = () => {
        clearInterval(intervalId)
    }

    const changeStatus = (): void => {
        setStatus(!status)
    }

    return (
        <div
            style={{ padding: "15px" }}
            className="dashboard animated slideInUpTiny animation-duration-3"
        >

            <ContainerHeader
                match={match}
                title={"Dashboard"}
            />
            <div className="rounded bg-light text-center">
                <p><b>Fecha ultima actualización: </b>{formatearFecha(lastUpdate, formatosFecha.horaServidor)}  <button className='btn btn-primary btn-sm rounded-circle mx-2' onClick={changeStatus}>{status ? <Pause /> : <PlayArrow />}</button></p>
            </div>
            <FilterEntity onClick={(dataEntity: any) => setEmpresaSelect(dataEntity)} />
            <BarraIndicadores updateData={update} identificadorEstacion={empresaSelect} />
            <PanelAlertas updateData={update} identificadorEstacion={empresaSelect} />
            <ChartVentaCombustiblePorMeses updateData={update} identificadorEstacion={empresaSelect} identificadorNegocio={negocio} />
            {/*<ChartVentaMeses updateData={update} identificadorEstacion={empresaSelect} identificadorNegocio={negocio} />*/}
            <ChartVentaCombustible7dias updateData={update} identificadorEstacion={empresaSelect} />
            <ChartDiasMasVentas updateData={update} identificadorEstacion={empresaSelect} />
            <ChartTopPromotores updateData={update} identificadorEstacion={empresaSelect} />
            <ChartTopProductos updateData={update} identificadorEstacion={empresaSelect} />
            <ChartTopEstaciones updateData={update} identificadorEstacion={empresaSelect} />
            <ChartTopVolumenes updateData={update} identificadorEstacion={empresaSelect} />
            {/*<ChartSaldosTanques updateData={update} identificadorEstacion={empresaSelect} />*/}
            <ChartIslasAndProductos updateData={update} identificadorEstacion={empresaSelect} identificadorNegocio={negocio} />
        </div>
    )
}

export default Dashboard
