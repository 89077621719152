const server = ''

let puertoCentury = process.env.REACT_APP_CENTURY
let puertoApp = process.env.REACT_APP_APP
let puertoMovimiento = process.env.REACT_APP_MOVIMIENTO
let puertoEquipo = process.env.REACT_APP_EQUIPO
let puertoMultimedia = process.env.REACT_APP_MULTIMEDIA
let puertoLazoLite = process.env.REACT_APP_LAZOLITE
let puertoFacturacion = process.env.REACT_APP_FACTURACION
let puertoAnalyzer= 9002
let host = process.env.REACT_APP_HOST
let HostPDF = process.env.REACT_APP_HOST
let hostMultimedia = process.env.REACT_APP_HOST_MULTIMEDIA
const hostMqtt = process.env.REACT_APP_HOST_MQTT
const mqttUsername = process.env.REACT_APP_USER_MQTT
const mqttPassword = process.env.REACT_APP_PASS_MQTT
const mqttPort = process.env.REACT_APP_MQTT

const responseOK = 200
const responseSucces = 201
const responseSesionCaducada = 205
const responseError = 400
const responseErrorServidor = 401
const versionApp = '2.4'
const versionCode = '2.4.80'
const aplicacion = process.env.REACT_APP_APPLICATION
const ntoken = 'Bearer '.concat(localStorage.getItem('token'))
const server_key = process.env.REACT_APP_KEY

const servers = {
    oficina: 'oficina',
    local: 'local',
    nestor: 'nestor'
}

function obtenerToken(){
    return 'Bearer '.concat(localStorage.getItem('token'))
}

switch (server) {
    case servers.oficina:
        host = 'http://192.168.0.225:'
        HostPDF = 'http://192.168.0.101:'
        puertoCentury = '6001'
        puertoApp = '7010'
        break
    case servers.local:
        host = 'http://localhost:'
        HostPDF = 'http://localhost:'
        puertoCentury = '6001'
        puertoApp = '6010'
        break
    case servers.nestor:
        host = 'http://192.168.0.7:'
        HostPDF = 'http://192.168.0.7:'
        puertoCentury = '6001'
        puertoApp = '7010'
        break
    default:
        break;
}

export default {
    //hosts
    host,
    hostMultimedia,
    HostPDF,
    //ports
    puertoApp,
    puertoCentury,
    puertoMovimiento,
    puertoEquipo,
    puertoMultimedia,
    puertoLazoLite,
    puertoAnalyzer,
    puertoFacturacion,
    //keys
    server_key,
    ntoken,
    versionApp,
    versionCode,
    aplicacion,
    //response
    responseOK,
    responseSucces,
    responseError,
    responseSesionCaducada,
    responseErrorServidor,
    //mqtt
    hostMqtt,
    mqttUsername,
    mqttPassword,
    mqttPort,
    obtenerToken
}
