import { columnsDatatable, columnsPDF } from 'components/Datatable'
import { DOMINIOS } from 'constants/centuryConstans'
import XLSX from 'xlsx'
import Storage from 'util/store/Storage';

export const LeerExcel = async (archivo: any): Promise<any> => {
  return new Promise(function (resolve, reject) {
    const reader = new FileReader()
    reader.onload = (e: any) => {
      var data = e.target.result
      let readedData = XLSX.read(data, { type: 'binary' })
      const wsname = readedData.SheetNames[0]
      const ws = readedData.Sheets[wsname]

      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 })
      resolve(dataParse)
    }
    reader.readAsBinaryString(archivo)
  })
}

export const objetoVacio = (objeto: object | null): boolean => {
  if (objeto === null || Object.entries(objeto).length === 0) {
    return true
  }
  return false
}

export const numeroValido = (numero: number | string): boolean => {
  if (
    (numero + '').trim().length > 0 &&
    validarExpresionRegular(numero + '', /^[0-9]+([.][0-9]+)?$/) &&
    !isNaN(parseFloat(numero + '')) &&
    parseFloat(numero + '') > 0
  ) {
    return true
  }
  return false
}

export const esNumero = (numero: number | string): boolean => {
  if (validarExpresionRegular(numero + '', /^[0-9]+([.][0-9]+)?$/)) {
    return true
  }
  return false
}

export const validarExpresionRegular = (str: string, expresionRegular: RegExp): boolean => {
  return expresionRegular.test(str)
}

export const transformDataTableToPdfModel = (columnas: columnsDatatable[]): columnsPDF[] => {
  return columnas.map(columna => ({
    header: columna.header,
    dataKey: columna.field
  }))
}

export const textoCentradoPDF = (doc: any, text: string, alto: number): any => {
  const textWidth = (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) / doc.internal.scaleFactor
  const textOffset = (doc.internal.pageSize.width - textWidth) / 2
  return doc.text(textOffset, alto, text)
}


export const separators = ( doc: any, alto: number) => {

  doc.setLineWidth(0.5);
  const totalLength = 500;
  const centerWidth = doc.internal.pageSize.getWidth() / 2 ;

  // Ancho del espacio entre los segmentos de la línea
  const segmentWidth = 2;

  // Calcular la posición X inicial para que los segmentos estén centrados
  const startX = centerWidth - (totalLength / 2);

  // Dibujar la línea segmentada centrada
  for (let i = startX; i < centerWidth + (totalLength / 2); i += segmentWidth * 2) {
    doc.line(i, alto, i + segmentWidth, alto);
  }
}
export const headerPdf = (doc: any, razonSocial: string, alias: string, nit: string, address: string = 'Direccion', contact: string = '123456') => {

  const width = doc.internal.pageSize.getWidth();
  const margin = 20;
  const fontSize = 12;
  const alingText = { align: 'center' }

  const centerWidth = width / 2

 /*  const logo = 'Logo_Estacion_Servicio.png';
  const logoWidth = 50;
  const logoHeight = 50;
  const logoX = margin;
  const logoY = margin; */
  
  // Agregar el título
  // const razonSocial = 'Razon SOCIAL S.A.S';
  
  const titleFontSize = 18;
  const titleY = margin + 10; // Espacio debajo del logo
  
  
  // const alias = 'EDS ALIAS';
  const aliasY = titleY + 15
  
  
  // const nit = '9007956956-1';
  const nitY = aliasY + 15
  
  const resposableIva = 'RESPONSABLE DEL IVA';
  const responsableY = nitY + 15

  // Agregar la dirección
  // const address = 'Calle Principal 123';
  const addressY = responsableY + 15; // Espacio debajo del título
  
  // Agregar el número de contacto
  // const contact = '123-456-7890';
  const contactY = addressY + 15; // Espacio debajo de la dirección
  
  // espacio del separator
  const lineY = contactY + 15;
  
  // Agregar imagen del logo
  // doc.addImage(logo, 'PNG', logoX, logoY, logoWidth, logoHeight);
  
  // Agregar título
  doc.setFontSize(titleFontSize);
  doc.text(razonSocial, centerWidth, titleY, alingText);
  
  doc.setFontSize(fontSize);
  doc.text(alias, centerWidth, aliasY, alingText);

  doc.setFontSize(fontSize);
  doc.text(`NIT: ${nit}`, centerWidth, nitY, alingText);

  doc.setFontSize(fontSize);
  doc.text(resposableIva, centerWidth, responsableY, alingText);

  doc.setFontSize(fontSize);
  doc.text(address, centerWidth, addressY, alingText);
  
  // Agregar número de contacto
  doc.setFontSize(fontSize);
  doc.text(`TEL: ${contact}`, centerWidth, contactY, alingText);
  
  // Agregar líneas divisorias
  
  separators(doc ,lineY)

}

export const footerPdf = (doc: any, text:string)=> {

    const totalPages = doc.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      doc.setPage(i);
      const footerX = doc.internal.pageSize.getWidth() / 2;
      const footerY = doc.internal.pageSize.getHeight() - 10;
      doc.text(text, footerX, footerY, { align: 'center' });
    }

}

export const EtiquetaBajoTablaPDF = (doc: any): number => {
  return doc.lastAutoTable.finalY + 15
}

export const snakeCaseToSeparatedUppercase = (element: any): any => {
  if (Array.isArray(element)) {
    return element.map(snakeCaseToSeparatedUppercase)
  } else if (typeof element === 'object' && element !== null) {
    const cloneObject: { [key: string]: string } = {}
    const keys = Object.keys(element)
    keys.forEach((key: string) => {
      const separatedKey = key.toUpperCase().replace(/_/g, ' ')
      const value = element[key]
      cloneObject[separatedKey] = snakeCaseToSeparatedUppercase(value)
    })
    Object.setPrototypeOf(cloneObject, Object.getPrototypeOf(element))
    return cloneObject
  }
  return element
}

export const esDominioDatagas = (dominio: any) => {
  try {
    return dominio.atributos.tipo_dominio === DOMINIOS.DATAGAS
  } catch (error) {
    return false
  }
}
export const esDominioTerpel = (dominio: any) => {
  try {
    return dominio.atributos.tipo_dominio === DOMINIOS.TERPEL
  } catch (error) {
    return false
  }
}
export const esDominioFuelly = (dominio: any) => {
  try {
    return dominio.atributos.tipo_dominio === DOMINIOS.FUELLY
  } catch (error) {
    return false
  }
}
export const esDominioFirefuel = (dominio: any) => {
  try {
    return dominio.atributos.tipo_dominio === DOMINIOS.FIREFUEL
  } catch (error) {
    return false
  }
}

export const Logger = (mensaje: string, descripcion?: string) => {
  console.log('-------------------------------------------')
  console.log(`             ${descripcion}                `)
  console.log(`${mensaje}`)
  console.log('-------------------------------------------')
}

export const LoggerError = (mensaje: string, funcion?: string, archivo?: string) => {
  console.log('-------------------------------------------')
  console.log(`%cError en ${archivo ?? ''}.${funcion ?? ''}`, 'color: red; font-size: 20px')
  console.log(`%c${mensaje}`, 'color:red')
  console.log('-------------------------------------------')
}

export const cerrarFormularioExterno = () => {
  Storage.dispatch({
    type: 'FORMULARIO_EXTERNO',
    formularioExterno: false,
    metodoFormulario: '',
    formularioId: 0
  })
}

interface IContactos {
  contacto: string
  tipo: number
  principal: string
  descripcion: string
}
export const obtenerContacto = (contactos: IContactos[], tipo: number): string => {
  try {
    return contactos.find((contacto) => contacto.tipo === tipo)?.contacto ?? 'N/A'
  } catch (error) {
    return ''
  }
}
