import Axios from 'axios'
import server from 'util/Server'
import Storage from 'util/store/Storage'
import moment from 'moment'
import { sessionCaducada } from 'util/funciones'
const headers = {
    Authorization: server.obtenerToken(),
    versionapp: server.versionApp,
    versioncode: server.versionCode,
    fecha: moment().format(),
    aplicacion: server.aplicacion,
    identificadordispositivo: server.aplicacion,
};
export const getEmpresasHijos = async () => {
    const { empresasHijos } = Storage.getState();
    return new Promise(function (resolve, reject) {
        Axios.get(
            server.host +
            server.puertoCentury +
            "/api/empresa/select/empresa/5/" +
            empresasHijos,
            { headers: { Authorization: 'Bearer '.concat(localStorage.getItem('token')) } }
        )
            .then((res) => {
                if (res.status === server.responseOK) {
                    const datos = res.data.data;
                    let estaciones = [];
                    if (datos != null) {
                        datos.forEach((empresa) => {
                            estaciones.push(empresa);
                        });
                    }
                    resolve(estaciones)
                } else if (res.status === server.responseSesionCaducada) {
                    sessionCaducada()
                }
            })
            .catch((err) => {
                reject(err, undefined)
            })
    })

}


export const getIndicadoresFuelly = async (sendFetch) => {
    return new Promise(function (resolve, reject) {
        Axios.post(
            server.host +
            server.puertoMovimiento +
            "/api/reportes/dashboard/FUELLYMX",
            sendFetch,
            { headers: headers }
        )
            .then((res) => {
                if (res.status === server.responseOK) {
                    const datos = res.data.data;
                    let indicadores = {}
                    if (datos && datos[0]) {
                        indicadores = datos[0]
                    }
                    resolve(indicadores)
                } else if (res.status === server.responseSesionCaducada) {
                    sessionCaducada()
                }
            })
            .catch((err) => {
                reject(err, undefined)
            })
    })

}


export const getIndicadoresDatagas = async (sendFetch) => {
    return new Promise(function (resolve, reject) {
        Axios.post(
            server.host +
            server.puertoMovimiento +
            "/api/reportes/dashboard/DATAGAS",
            sendFetch,
            { headers: headers }
        )
            .then((res) => {
                if (res.status === server.responseOK) {
                    const datos = res.data.data;
                    let indicadores = {}
                    if (datos[0]) {
                        indicadores = datos[0]
                    }
                    resolve(indicadores)
                } else if (res.status === server.responseSesionCaducada) {
                    sessionCaducada()
                }
            })
            .catch((err) => {
                reject(err, undefined)
            })
    })

}

export const getIndicadoresLazo = async (sendFetch) => {
    return new Promise(function (resolve, reject) {
        Axios.post(
            server.host +
            server.puertoMovimiento +
            "/api/reportes/dashboard-lite/lazo",
            sendFetch,
            { headers: headers }
        )
            .then((res) => {
                if (res.status === server.responseOK) {
                    const datos = res.data.data;
                    let indicadores = {}
                    if (datos[0]) {
                        indicadores = datos[0]
                    }
                    resolve(indicadores)
                } else if (res.status === server.responseSesionCaducada) {
                    sessionCaducada()
                }
            })
            .catch((err) => {
                reject(err, undefined)
            })
    })

}

export const getIndicadoresLazoLite = async (sendFetch) => {
    return new Promise(function (resolve, reject) {
        Axios.post(
            server.host +
            server.puertoMovimiento +
            "/api/reportes/dashboard-lite/lazo",
            sendFetch,
            { headers: headers }
        )
            .then((res) => {
                if (res.status === server.responseOK) {
                    const datos = res.data.data;
                    let indicadores = {}
                    if (datos[0]) {
                        indicadores = datos[0]
                    }
                    resolve(indicadores)
                } else if (res.status === server.responseSesionCaducada) {
                    sessionCaducada()
                }
            })
            .catch((err) => {
                reject(err, undefined)
            })
    })

}

export const alertaSecadoVirtual = async () => {
    const { empresasHijos } = Storage.getState();
    const headers = {
        Authorization: server.obtenerToken(),
        versionapp: server.versionApp,
        versioncode: server.versionCode,
        fecha: moment().format(),
        aplicacion: server.aplicacion,
        identificadordispositivo: server.aplicacion,
    };
    const data = {
        fecha_inicial: moment(new Date()).format("YYYY-MM-DD 00:00:00"),
        fecha_final: moment(new Date()).format("YYYY-MM-DD 24:00:00"),
        identificadorEstacion: empresasHijos,
    };
    return new Promise(async function (resolve, reject) {
        Axios.post(server.host + server.puertoMovimiento + "/api/reportes/dashboard_lite/ALERTA_TANQUES", data, { headers: headers })
            .then((res) => {
                if (res.status === server.responseOK) {
                    const datos = res.data.data;
                    let tanques = []
                    if (datos) {
                        tanques = datos
                    }
                    resolve(tanques)
                } else if (res.status === server.responseSesionCaducada) {
                    sessionCaducada()
                }
            })
            .catch((err) => {
                reject(err, undefined)
            })
    })
};



